import type { ApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'

export const extractComortgagorInfo = (
  accounts: ApplicationIntroductionQuery['accounts'],
  profile: NonNullable<ApplicationIntroductionQuery['project']>['profile'] | null | undefined
) => {
  const mortgagors = profile?.mortgagors ?? []
  const hasComortgagor = mortgagors?.length > 1
  const isComortgagorInvited = !!accounts[1]?.email
  const comortgagorContact = accounts[1]
    ? {
        firstname: accounts[1].firstName,
        lastname: accounts[1].lastName,
        email: accounts[1].email,
        phone: accounts[1].phone,
      }
    : null

  return { hasComortgagor, isComortgagorInvited, comortgagorContact }
}
