import { breakpoints, g } from '@pretto/bricks/components/layout'

import {
  PAGE_AFTER_INTRODUCTION,
  PAGE_BEFORE_INTRODUCTION,
} from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { InputSearchAndChips } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/InputSearchAndChips/InputSearchAndChips'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { BankIndex } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/bankIndex'
import { useTracking } from '@pretto/app/src/lib/tracking'
import BANKS from '@pretto/config/banks.json'

import { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

export const BankAccountsStep: React.FC = () => {
  const { push } = useHistory()
  const trackAction = useTracking()

  const [isLoading, setIsLoading] = useState(false)
  const banksList = BANKS.map(({ value, label }) => ({ value, label })) as BankIndex[]

  const {
    bankAccounts,
    comortgagorBankAccounts,
    comortgagorContact,
    hasComortgagor,
    hasJointAccount,
    jointAccounts,
    maturity,
    persistIntroduction,
    setContext,
    userFirstName,
  } = useIntroduction()

  const { goToPreviousRoute } = useRoutesGraph()

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const hasValidBankAccounts = (accounts: string[] | undefined) => Boolean(accounts?.length)

  const nextRouteCondition = () => {
    if (!hasComortgagor) {
      return hasValidBankAccounts(bankAccounts)
    }

    if (hasComortgagor && !hasJointAccount) {
      return hasValidBankAccounts(bankAccounts) && hasValidBankAccounts(comortgagorBankAccounts)
    }

    if (hasComortgagor && hasJointAccount) {
      return (
        hasValidBankAccounts(bankAccounts) &&
        hasValidBankAccounts(comortgagorBankAccounts) &&
        hasValidBankAccounts(jointAccounts)
      )
    }

    return false
  }

  const handleGetUserCounts = (selectedOptions: string[]) => {
    setContext({ bankAccounts: selectedOptions })
  }

  const handleGetJointAccounts = (selectedOptions: string[]) => {
    setContext({ jointAccounts: selectedOptions })
  }

  const handleGetComortgagorCounts = (selectedOptions: string[]) => {
    setContext({ comortgagorBankAccounts: selectedOptions })
  }

  const handleEndForm = async () => {
    setIsLoading(true)

    await persistIntroduction()

    setIsLoading(false)

    trackAction('APPLICATION_INTRODUCTION_SUBMITTED')

    push(PAGE_AFTER_INTRODUCTION)
  }

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition() ? handleEndForm : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
      isLoading={isLoading}
    >
      <>
        <Title>Vos comptes bancaires</Title>
        {!hasComortgagor && (
          <QuestionBloc>
            <Sentences>
              Dans quelle(s) banque(s) avez-vous un <strong>compte courant</strong> ?
            </Sentences>
            <InputSearchAndChips
              preSelectedOptions={bankAccounts}
              optionsList={banksList}
              placeholder={'Ajouter une banque'}
              getSelectedOption={handleGetUserCounts}
            />
          </QuestionBloc>
        )}

        {hasComortgagor && hasJointAccount && (
          <QuestionBloc>
            <Sentences>
              Dans quelle(s) banque(s) avez-vous un <strong>compte joint</strong> avec votre co-emprunteur ?
            </Sentences>
            <InputSearchAndChips
              preSelectedOptions={jointAccounts}
              optionsList={banksList}
              placeholder={'Ajouter une banque'}
              getSelectedOption={handleGetJointAccounts}
            />
          </QuestionBloc>
        )}

        {hasComortgagor && comortgagorContact && (
          <>
            <QuestionBloc>
              <Sentences>{`Compte(s) courant(s) de ${userFirstName} :`}</Sentences>

              <InputSearchAndChips
                preSelectedOptions={bankAccounts}
                optionsList={banksList}
                placeholder={'Ajouter une banque'}
                getSelectedOption={handleGetUserCounts}
              />
            </QuestionBloc>

            <QuestionBloc>
              <Sentences>{`Compte(s) courant(s) de ${comortgagorContact.firstname} :`}</Sentences>

              <InputSearchAndChips
                preSelectedOptions={comortgagorBankAccounts}
                optionsList={banksList}
                placeholder={'Ajouter une banque'}
                getSelectedOption={handleGetComortgagorCounts}
              />
            </QuestionBloc>
          </>
        )}
      </>
    </StepsTemplate>
  )
}

const QuestionBloc = styled.div`
  & + & {
    margin-top: ${g(3)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      margin-top: ${g(4)};
    }
  }
`
