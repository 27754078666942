import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { matchBankSlugAndId } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/matchBankSlugAndId'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { InputSearchAndChips } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/InputSearchAndChips/InputSearchAndChips'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { useTracking } from '@pretto/app/src/lib/tracking'
import BANKS from '@pretto/config/banks.json'

import { useHistory } from 'react-router'

export const BanksToAvoidStep: React.FC = () => {
  const { banksToAvoid, hasComortgagor, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const banksList = BANKS.map(({ value, label }) => ({ value, label })) as typeof BANKS

  const nextRouteCondition = Boolean(banksToAvoid?.length)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const handleGetSelectedOption = (selectedOptions: string[]) => {
    setContext({ banksToAvoid: matchBankSlugAndId({ banksToAvoid, selectedBanks: selectedOptions }) })
  }

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Vos allergies bancaires</Title>

      <Sentences>Quelle(s) banque(s) souhaitez vous éviter ?</Sentences>

      <InputSearchAndChips
        preSelectedOptions={
    banksToAvoid?.reduce<string[]>((acc, { bank_slug }) => {
        if (bank_slug) acc.push(bank_slug)
        return acc
    }, []) || []
        }
        optionsList={banksList}
        placeholder={'Ajouter une banque'}
        getSelectedOption={handleGetSelectedOption}
      />
    </StepsTemplate>
  )
}
