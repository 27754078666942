import type { Bank } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/bank'

type MatchBankSlugAndIdProps = { banksToAvoid?: Bank[]; selectedBanks: string[] }

export const matchBankSlugAndId = ({ banksToAvoid = [], selectedBanks }: MatchBankSlugAndIdProps) => {
  if (banksToAvoid.length === 0) {
    return selectedBanks.map(bank_slug => ({ bank_slug, id: null }))
  }

  return selectedBanks.map(bank_slug => {
    const found = banksToAvoid.find(item => item.bank_slug === bank_slug)

    return { bank_slug, id: found ? found.id : null }
  })
}
