import { g } from '@pretto/bricks/components/layout'

import { Option as OptionComponent } from '@pretto/zen/reveal/atoms/textfields/Option/Option'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import styled, { css } from 'styled-components'

const MAX_ITEMS = 6
const ITEMS_HEIGHT = 40

type OptionsListProps = {
  $numberOfOptions: number
}

export const OptionsList = styled.ul<OptionsListProps>`
  display: flex;
  flex-direction: column;
  padding: ${g(1)};
  border-radius: ${g(1)};
  box-shadow: 0px 2px 8px 0px ${({ theme }) => addAlpha(theme.colors.neutral1, 0.15)};
  background-color: ${({ theme }) => theme.colors.white};
  max-height: ${MAX_ITEMS * ITEMS_HEIGHT + 16}px;
  overflow: auto;
  z-index: 2;

  ${({ $numberOfOptions }) =>
    $numberOfOptions > MAX_ITEMS &&
    css`
      padding-right: 0px;
    `}

  /* WebKit and Chromiums */
  ::-webkit-scrollbar {
    width: ${g(3)};
    padding: ${g(1)};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary1};
    background-clip: content-box;
    border: ${g(1)} solid transparent;
    border-radius: ${g(10)};
    max-height: 4px;
  }
`

export const Option = styled(OptionComponent)`
  width: 100%;

  &:focus {
    z-index: 100;
  }
`

export const NoResults = styled.li`
  ${({ theme }) => theme.typos.body4};
  color: ${({ theme }) => theme.colors.neutral2};
  display: flex;
  align-items: center;
  padding: ${g(1)} ${g(2)};
  width: 100%;
  border-radius: ${g(1 / 2)};
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  outline-offset: 1px;
`
