import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Message } from '@pretto/app/src/Sentences/components/Styled/Message/Message'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { isNil } from 'lodash'
import { useState } from 'react'
import { useHistory } from 'react-router'

export const BanksStep: React.FC = () => {
  const { hasBanksToAvoid, hasComortgagor, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const nextRouteCondition = !isNil(hasBanksToAvoid)

  const [activeField, setActiveField] = useState<number | null>(nextRouteCondition ? null : 0)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const choicesHasBanksToAvoid = [
    {
      key: 'hasBanksToAvoid',
      isSelected: hasBanksToAvoid === true,
      onClick: () => {
        setContext({ hasBanksToAvoid: true })
        setActiveField(null)
        goToNextRoute()
      },
      label: `y a`,
    },
    {
      key: 'hasNoBankToAvoid',
      isSelected: hasBanksToAvoid === false,
      onClick: () => {
        setContext({ hasBanksToAvoid: false, banksToAvoid: [] })
        setActiveField(null)
        goToNextRoute()
      },
      label: `n'y a pas`,
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Vos allergies bancaires</Title>

      <Sentences>
        Il
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!isNil(hasBanksToAvoid)}
        >
          {choicesHasBanksToAvoid[hasBanksToAvoid ? 0 : 1].label}
        </Prompt>
        {`de${hasBanksToAvoid ? 's' : ''} banque${hasBanksToAvoid ? 's' : ''} à éviter pour votre crédit.`}
      </Sentences>

      <Message>
        Si vous ne souhaitez pas prendre votre crédit auprès d’une certaine banque, votre expert respectera cette
        préférence.
      </Message>

      {activeField === 0 && <Choices withoutTracking choices={choicesHasBanksToAvoid} />}
    </StepsTemplate>
  )
}
