import { FormatContextToGatewayResult } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/formatContextToGateway'
import type { ApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'

type ValuesToUpdateProps = {
  previousProject: ApplicationIntroductionQuery['project']
  formatedFormData: FormatContextToGatewayResult
}

export const valuesToUpdate = ({ previousProject, formatedFormData }: ValuesToUpdateProps) => ({
  ...formatedFormData,
  profile: {
    ...formatedFormData.profile,
    mortgagors:
      previousProject?.profile?.mortgagors &&
      previousProject?.profile.mortgagors.map((mortgagor, index) => ({
        ...mortgagor,
        ...(formatedFormData.profile?.mortgagors?.[index] || {}),
      })),
  },
  ...(formatedFormData.purchase && { purchase: { ...formatedFormData.purchase } }),
})
