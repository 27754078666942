// This is the old way to check if the application introduction is done.
// We still need it during the transition period to the new way using ProjectKickoffSteps.

type isApplicationIntroductionDoneProps = {
  accounts: {
    email?: string
    firstName?: string
    lastName?: string
    phone?: string
  }[]
  profile: {
    joint_banks: string[]
    mortgagors: {
      current_banks: string[]
      banking_incident: boolean
    }[]
  }
}

export const isApplicationIntroductionDone = ({ accounts, profile }: isApplicationIntroductionDoneProps) => {
  const areAccountsInfoFiled =
    accounts.every(({ firstName, lastName, email, phone }) => firstName && lastName && email && phone) &&
    profile.mortgagors.length === accounts.length

  const isBanksInfoFiled =
    profile.mortgagors.every(({ current_banks }) => current_banks.length > 0) || profile.joint_banks.length > 0

  const isBankingIncidentFiled = profile.mortgagors.every(
    ({ banking_incident }) => typeof banking_incident === 'boolean'
  )

  return areAccountsInfoFiled && isBanksInfoFiled && isBankingIncidentFiled
}
