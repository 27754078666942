import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { useApplication } from '@pretto/app/src/Application/Containers/ApplicationContext'
import { BankAccountsStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/BankAccountsStep'
import { BanksStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/BanksStep'
import { BanksToAvoidStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/BanksToAvoidStep'
import { BrokerStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/BrokerStep'
import { BrokersProposalStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/BrokersProposalStep'
import { ComortgagorStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/ComortgagorStep'
import { IntroductionStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/IntroductionStep'
import { JointAccountStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/JointAccountStep'
import { NotaryContactStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/NotaryContactStep'
import { NotaryStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/NotaryStep'
import { OverdraftStep } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/containers/OverdraftStep'
import { IntroductionContextProvider } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { RoutesGraphContextProvider } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { applicationGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/graph/applicationGraph'
import { ModalLikePage } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/ModalLikePage/ModalLikePage'

import { Redirect, Route, Switch } from 'react-router-dom'

export const ApplicationIntroductionForm: React.FC = () => {
  const { introductionUrl, isIntroductionDone } = useApplication()

  if (isIntroductionDone) {
    return <Redirect to="/application" />
  }

  return (
    <ThemeProvider designSystem="reveal">
      <IntroductionContextProvider>
        <RoutesGraphContextProvider>
          <ModalLikePage>
            <Switch>
              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/sollicited-broker-rates`)}
                component={BrokersProposalStep}
              />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/sollicited-broker`)}
                component={BrokerStep}
              />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/banks/overdraft`)}
                component={OverdraftStep}
              />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/banks/joint-account`)}
                component={JointAccountStep}
              />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/banks/bank-accounts`)}
                component={BankAccountsStep}
              />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/avoid-banks`)}
                component={BanksToAvoidStep}
              />

              <Route path={applicationGraph.matchRoute(`${introductionUrl}/banks`)} component={BanksStep} />

              <Route path={applicationGraph.matchRoute(`${introductionUrl}/project`)} component={ComortgagorStep} />

              <Route
                path={applicationGraph.matchRoute(`${introductionUrl}/notary/contact`)}
                component={NotaryContactStep}
              />

              <Route path={applicationGraph.matchRoute(`${introductionUrl}/notary`)} component={NotaryStep} />

              <Route path={applicationGraph.matchRoute(`${introductionUrl}`)} component={IntroductionStep} />

              <Redirect to={introductionUrl} />
            </Switch>
          </ModalLikePage>
        </RoutesGraphContextProvider>
      </IntroductionContextProvider>
    </ThemeProvider>
  )
}
