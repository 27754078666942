import { breakpoints, g } from '@pretto/bricks/components/layout'

import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { IntroductionTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/IntroductionTemplate/IntroductionTemplate'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

export const IntroductionStep = () => {
  const { push } = useHistory()
  const trackAction = useTracking()
  const { goToNextRoute } = useRoutesGraph()
  const { applicationIntroductionShown, isQueryLoading } = useIntroduction()

  useEffect(() => {
    trackAction('APPLICATION_INTRODUCTION_PAGE_LOADED')
    applicationIntroductionShown()
  }, [])

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_BUTTON_CLICKED')
    goToNextRoute()
  }

  return (
    <IntroductionTemplate
      isQueryLoading={isQueryLoading}
      onNext={handleNext}
      timeLineProps={{ currentStepIndex: 2, steps: ['Simulation', 'Résultats', 'Informations', 'Documents'] }}
    >
      <Tag>Votre dossier</Tag>

      <Title>Avant de déposer vos documents…</Title>

      <Description>
        Nous avons quelques questions à vous poser pour préparer votre dossier. Vous pourrez ensuite nous transmettre
        vos documents !
      </Description>

      <GoBackButton onClick={handleClose}>Retour à l&apos;espace client</GoBackButton>
    </IntroductionTemplate>
  )
}

const Tag = styled.div`
  ${({ theme }) => theme.typos.heading6};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary1};
  padding: ${g(1)} ${g(1, 4)};
  border-radius: ${g(1, -4)};
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
`

const Title = styled.div`
  ${({ theme }) => theme.typos.heading4};
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(2)};
  }
`

const Description = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-top: ${g(2)};
`

const GoBackButton = styled.button`
  color: inherit;
  text-decoration: none;
  ${({ theme }) => theme.typos.captionUnderline};
  margin-top: ${g(3)};
  cursor: pointer;
`
