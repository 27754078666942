import { breakpoints, g } from '@pretto/bricks/components/layout'

import { ProgressBar } from '@pretto/zen/reveal/atoms/progressBars/progressBar/ProgressBar'

import { ArrowLeft, TimesBold } from '@pretto/picto'

import styled from 'styled-components'

export const StyledProgressHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${g(1)} ${g(2, 4)};
  gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: ${g(4)};
    gap: ${g(6)};
  }
`

export const GoBackButton = styled.button`
  ${({ theme }) => theme.typos.button};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${g(2)};
  cursor: pointer;
`

export const StyledArrowLeft = styled(ArrowLeft)`
  height: ${g(4)};
  width: ${g(4)};
  flex-shrink: 0;
`

export const ButtonText = styled.span`
  display: none;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
  }
`

export const StyledProgressBar = styled(ProgressBar)`
  height: 100%;
  width: 100%;
`

export const CloseButton = styled.button`
  ${({ theme }) => theme.typos.linkUnderline};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    width: 102px;
  }
`

export const PictoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${g(4)};
  width: ${g(4)};
  padding: ${g(2)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const StyledTimesBold = styled(TimesBold)`
  height: ${g(2)};
  width: ${g(2)};
  flex-shrink: 0;
`
