import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { isNil } from 'lodash'
import { useState } from 'react'
import { useHistory } from 'react-router'

export const NotaryStep: React.FC = () => {
  const { hasComortgagor, hasNotaryContact, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const nextRouteCondition = !isNil(hasNotaryContact)

  const [activeField, setActiveField] = useState<number | null>(nextRouteCondition ? null : 0)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const choicesNotaryContact = [
    {
      key: 'hasNotaryContact',
      isSelected: hasNotaryContact === true,
      onClick: () => {
        setContext({ hasNotaryContact: true })
        setActiveField(null)
        goToNextRoute()
      },
      label: `connaissez`,
    },
    {
      key: 'hasNotNotaryContact',
      isSelected: hasNotaryContact === false,
      onClick: () => {
        setContext({ hasNotaryContact: false, notaryContact: null })

        setActiveField(null)
        goToNextRoute()
      },
      label: `ne connaissez pas`,
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={handleNext}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Votre notaire</Title>

      <Sentences>
        Vous
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!isNil(hasNotaryContact)}
        >
          {choicesNotaryContact[hasNotaryContact ? 0 : 1].label}
        </Prompt>
        les coordonnées de votre notaire.
      </Sentences>

      {activeField === 0 && <Choices withoutTracking choices={choicesNotaryContact} />}
    </StepsTemplate>
  )
}
