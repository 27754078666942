import { STEPS_ACCESS } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/config/stepsAccess'
import { Maturity } from '@pretto/app/src/types/gateway/enums'

type GetProgressProps = { hasComortgagor?: boolean | null; maturity?: Maturity | null }

type Root = '/application/introduction/'
type Paths =
  | 'sollicited_broker'
  | 'sollicited-broker-rates'
  | 'banks'
  | 'avoid-banks'
  | 'project'
  | 'notary'
  | 'notary/contact'
  | 'banks/overdraft'
  | 'banks/joint-account'
  | 'banks/bank-accounts'

type LocationType = `${Root}${Paths}`

export const getProgress = ({ hasComortgagor = false, maturity }: GetProgressProps): number => {
  const location = window.location.pathname as LocationType

  const isHot = !!maturity && ![Maturity.UnderStudy, Maturity.SearchStarted].includes(maturity)

  const hasSaleAgreementSigned = maturity === Maturity.SaleAgreementSigned

  const visibleSteps = STEPS_ACCESS.filter(({ profileAccess }) => {
    const {
      hasComortgagor: accessComortgagor,
      hasNoComortgagor: accessNoComortgagor,
      hasNoSaleAgreementSigned: accessNoAgreement,
      hasSaleAgreementSigned: accessAgreement,
      isCold: accessCold,
      isHot: accessHot,
    } = profileAccess

    const hasAccessByAgreement = hasSaleAgreementSigned ? accessAgreement : accessNoAgreement
    const hasAccessByMaturity = isHot ? accessHot : accessCold
    const hasAccessByCoBorrower = hasComortgagor ? accessComortgagor : accessNoComortgagor

    return hasAccessByAgreement && hasAccessByMaturity && hasAccessByCoBorrower
  })

  const currentStepIndex = visibleSteps.findIndex(step => step.path === location)

  if (currentStepIndex === -1) return 0

  return Math.round(((currentStepIndex + 1) / visibleSteps.length) * 100)
}
