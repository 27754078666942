import { convertStatusSlug } from '@pretto/app-core/application/lib/convertStatusSlug'
import { getDocumentsProgress } from '@pretto/app-core/application/lib/getDocumentsProgress'
import { getNavigationItems } from '@pretto/app-core/application/lib/getNavigationItems'
import { getProgress } from '@pretto/app-core/application/lib/getProgress'
import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { APPLICATION_INTRODUCTION_URL } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { FolderOpen, Picto as PictoType } from '@pretto/picto'

import PropTypes from 'prop-types'
import { createContext, ReactNode, useContext } from 'react'
import { useLocation } from 'react-router'

import { useUser } from '../../../User/Containers/UserProvider'
import * as forms from '../../config'

import { ApplicationQuery, useApplicationQuery } from './application.gateway.graphql'

type DocumentStatus = {
  document_status: string
  kind: string
}
interface ApplicationContextProps {
  completionPercentage: number
  data: ApplicationQuery | undefined
  documentsProgress: {
    current: number
    target: number
  }
  introductionUrl: string
  isIntroductionDone: boolean
  navigationItems: Array<{
    isActive: boolean
    isComplete: boolean
    isErrored: boolean
    label: string
    picto: PictoType
    url: string
  }>
}
const ApplicationContext = createContext<ApplicationContextProps | undefined>(undefined)
interface ApplicationProviderProps {
  children: ReactNode
}
const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const location = useLocation()

  const { data: dataApplication, loading: loadingApplication, error: errorApplication } = useApplicationQuery()

  const userContext = useUser()

  useLoading(loadingApplication)

  if (errorApplication) {
    throw 'Error while fetching data in application context'
  }

  if (loadingApplication) {
    return null
  }

  const progress = getProgress(forms, dataApplication, userContext)
  const documentsProgress = getDocumentsProgress(
    dataApplication?.docs?.filter(
      ({ document_status }) => document_status !== undefined && document_status !== null
    ) as DocumentStatus[]
  )

  const value: ApplicationContextProps = {
    completionPercentage: Math.floor((progress.current / progress.target) * 100),
    data: dataApplication,
    documentsProgress,
    introductionUrl: APPLICATION_INTRODUCTION_URL,
    isIntroductionDone: userContext.isIntroductionDone,
    navigationItems: [
      ...getNavigationItems(forms, location.pathname, dataApplication, userContext),
      {
        isActive: location.pathname === '/application/documents',
        isComplete: documentsProgress?.current === documentsProgress?.target,
        isErrored: dataApplication?.docs?.some(
          ({ document_status }) => document_status && convertStatusSlug(document_status) === 'invalid'
        ),
        label: 'Documents',
        picto: FolderOpen,
        url: '/application/documents',
      },
    ],
  }
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
}
ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ApplicationProvider
export const useApplication = (): ApplicationContextProps => {
  const context = useContext(ApplicationContext)
  if (context === undefined) {
    throw new Error('useApplication must be used within an ApplicationProvider')
  }
  return context
}
