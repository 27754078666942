import { g } from '@pretto/bricks/components/layout'

import { TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

export type ChipProps = {
  value: string
  label: string
  onClose?: (value: string) => void
  isColored?: boolean
}

export const Chip = ({ isColored, onClose, label, value, ...props }: ChipProps) => (
  <StyledChip $isColored={isColored} {...props}>
    {label}

    {onClose && <Picto onClick={onClose.bind(null, value)} />}
  </StyledChip>
)

const StyledChip = styled.div<{ $isColored?: boolean }>`
  ${({ theme }) => theme.typos.body4};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${g(1)};
  color: ${({ theme, $isColored }) => theme.colors[$isColored ? 'white' : 'primary1']};
  background-color: ${({ theme, $isColored }) => theme.colors[$isColored ? 'primary1' : 'neutral4']};
  padding: ${g(1)} ${g(2)};
  border-radius: 100px;
  width: fit-content;

  ${({ theme, $isColored }) =>
    !$isColored &&
    css`
      &:hover {
        background-color: ${theme.colors.primary3};
      }
    `}
`

const Picto = styled(TimesBold)`
  height: ${g(2)};
  width: ${g(2)};
  fill: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`
