import type { ApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'

export const extractBrokerInfo = (
  solicitedBrokers: NonNullable<ApplicationIntroductionQuery['project']>['solicited_brokers'] | null | undefined
) => {
  if (!solicitedBrokers?.length)
    return { hasABroker: null, signedWithBroker: null, hasBrokerProposal: null, brokerProposal: null }

  const [firstBroker] = solicitedBrokers

  return {
    hasABroker: true,
    signedWithBroker: firstBroker.status === 'mandate_signed' ? true : null,
    hasBrokerProposal: firstBroker.rate ? true : null,
    brokerProposal:
      firstBroker.rate && firstBroker.duration
        ? { rate: firstBroker.rate.toString(), duration: firstBroker.duration.toString() }
        : null,
  }
}
