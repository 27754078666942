import { applicationGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/graph/applicationGraph'
import type { IntroductionContextType } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/introductionContext'
import { createRoutesGraphContext } from '@pretto/app/src/lib/graph/RoutesGraphContext'

import { useIntroduction } from './IntroductionContext'

const { Provider, useRoutesGraph } = createRoutesGraphContext<IntroductionContextType>(applicationGraph)

export { useRoutesGraph }

export const RoutesGraphContextProvider: React.FC = ({ children }) => {
  const context = useIntroduction()

  return (
    <Provider context={context} wait={context.waitForOngoingOperations}>
      {children}
    </Provider>
  )
}
