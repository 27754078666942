import { TextFieldDefault } from '@pretto/zen/reveal/atoms/textfields/TextFieldDefault/TextFieldDefault'
import { FieldState } from '@pretto/zen/reveal/types/Field'

import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { InputList } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/InputList/InputList'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useHistory } from 'react-router'

const WARNING_PROPS = {
  state: 'warning' as FieldState,
  message: 'Cette valeur semble importante, nous vous invitons à la vérifier.',
}
const MAX_RATE = 5
const MAX_DURATION = 35

export const BrokersProposalStep: React.FC = () => {
  const { brokerProposal, hasComortgagor, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const nextRouteCondition = brokerProposal?.rate && brokerProposal?.duration

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const inputsConfig = [
    {
      suffix: '%',
      inputProps: { placeholder: 'Taux', type: 'number' },
      value: brokerProposal?.rate || '',
      onChange: (value: string) => {
        setContext({ brokerProposal: { ...brokerProposal, rate: value || '' } })
      },
      ...(brokerProposal?.rate && Number(brokerProposal?.rate) > MAX_RATE ? WARNING_PROPS : {}),
    },
    {
      suffix: 'ans',
      inputProps: { placeholder: 'Durée', type: 'number' },
      value: brokerProposal?.duration || '',
      onChange: (value: string) => {
        setContext({ brokerProposal: { ...brokerProposal, duration: value ? value.replace(/\D+/g, '') : '' } })
      },
      ...(brokerProposal?.duration && Number(brokerProposal?.duration) > MAX_DURATION ? WARNING_PROPS : {}),
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Termes de la proposition</Title>

      <InputList>
        {inputsConfig.map((inputProps, index) => (
          <TextFieldDefault key={index} {...inputProps} />
        ))}
      </InputList>
    </StepsTemplate>
  )
}
