import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { ProgressHeader } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/ProgressHeader/ProgressHeader'

import styled from 'styled-components'

export const Template = styled.div`
  ${grid(4, { isLeftAligned: true, isRightAligned: true })};
  grid-template-rows: auto 1fr auto;
  height: 100%;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const StyledProgressHeader = styled(ProgressHeader)`
  ${column(4)}

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column(10)};
  }
`

export const MainContent = styled.div`
  ${column(4)};
  margin: ${g(4)} ${g(2, 4)} ${g(3)} ${g(2, 4)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 6])};
    margin: ${g(5)} 0px ${g(3)} 0px;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 8])};
    margin: ${g(8)} 0px ${g(5)} 0px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 6])};
  }
`

export const ButtonContainer = styled.div`
  ${column(4)};
  margin: 0px ${g(2, 4)} ${g(2)} ${g(2, 4)};
  display: flex;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
    margin: 0px ${g(3)} ${g(3)} ${g(3)};
    padding-bottom: ${g(3)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column(10)};
  }
`

export const NextButton = styled(PrimaryButtonFix)`
  width: 100%;
  text-align: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: unset;
    margin-left: auto;
  }
`
