import type { FormDataToSave } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/formDataToSave'

import { isNil } from 'lodash'

type Mortgagor = { banking_incident: boolean | null; current_banks?: string[] }

export type FormatContextToGatewayResult = {
  profile: { joint_banks?: string[]; mortgagors: Mortgagor[] }
  purchase?: { notary: { email?: string | null; name?: string | null; phone?: string | null } }
  solicited_banks?: { id?: string | null; bank_slug?: string | null; blocked?: boolean | null }[]
  solicited_brokers?: { duration?: number | null; name?: string | null; rate?: number | null; status?: string | null }[]
}

export const formatContextToGateway = (applicationContext: FormDataToSave): FormatContextToGatewayResult => ({
  profile: {
    ...(applicationContext.jointAccounts &&
      applicationContext.jointAccounts.length > 0 && { joint_banks: applicationContext.jointAccounts }),
    mortgagors: [
      { banking_incident: !!applicationContext.hasOverdraft, current_banks: applicationContext.bankAccounts },
      applicationContext.hasComortgagor && {
        ...(!isNil(applicationContext.comortgagorHasOverdraft) && {
          banking_incident: !!applicationContext.comortgagorHasOverdraft,
        }),
        current_banks: applicationContext.comortgagorBankAccounts,
      },
    ].filter(Boolean) as Mortgagor[],
  },
  ...(applicationContext.hasNotaryContact && {
    purchase: {
      notary: {
        email: applicationContext.notaryContact?.email,
        name: applicationContext.notaryContact?.lastname,
        phone: applicationContext.notaryContact?.phone,
      },
    },
  }),
  ...(applicationContext.hasBanksToAvoid && {
    solicited_banks: applicationContext.banksToAvoid?.map(({ id, bank_slug }) => ({ id, bank_slug, blocked: true })),
  }),
  ...(applicationContext.hasABroker && {
    solicited_brokers: [
      {
        ...(applicationContext.brokerProposal?.duration && {
          duration: Number(applicationContext.brokerProposal.duration),
        }),
        name: 'other',
        ...(applicationContext.brokerProposal?.rate && { rate: Number(applicationContext.brokerProposal.rate) }),
        status: applicationContext.signedWithBroker ? 'mandate_signed' : undefined,
      },
    ],
  }),
})
