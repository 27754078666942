import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export const BrokerStep: React.FC = () => {
  const { push } = useHistory()
  const trackAction = useTracking()

  const [displayNextButton, setDisplayNextButton] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number | null>(0)

  const { hasABroker, hasBrokerProposal, hasComortgagor, maturity, setContext, signedWithBroker } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()

  useEffect(() => {
    if (
      (hasABroker && signedWithBroker && !isNil(hasBrokerProposal)) ||
      (hasABroker && signedWithBroker === false) ||
      hasABroker === false
    ) {
      setActiveField(null)
      setDisplayNextButton(true)
    }
  }, [hasABroker, hasBrokerProposal, signedWithBroker])

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const choicesHasBroker = [
    {
      key: 'hasABroker',
      isSelected: hasABroker === true,
      onClick: () => {
        setContext({ hasABroker: true })
        setActiveField(1)
      },
      label: `Vous avez déjà`,
    },
    {
      key: 'hasNoBroker',
      isSelected: hasABroker === false,
      onClick: () => {
        setContext({ hasABroker: false, brokerProposal: null })
        goToNextRoute()
      },
      label: `Vous n'avez pas`,
    },
  ]

  const choicesHasSigned = [
    {
      key: 'hasSigned',
      isSelected: signedWithBroker === true,
      onClick: () => {
        setContext({ signedWithBroker: true })
        setActiveField(2)
      },
      label: `Vous avez`,
    },
    {
      key: 'hasNotSigned',
      isSelected: signedWithBroker === false,
      onClick: () => {
        setContext({ signedWithBroker: false })
        goToNextRoute()
      },
      label: `Vous n'avez pas encore`,
    },
  ]

  const choicesHasProposition = [
    {
      key: 'hasBrokerProposal',
      isSelected: hasBrokerProposal === true,
      onClick: () => {
        setContext({ hasBrokerProposal: true })
        goToNextRoute()
      },
      label: `vous a fait une`,
    },
    {
      key: 'hasNoBrokerProposal',
      isSelected: hasBrokerProposal === false,
      onClick: () => {
        setContext({ hasBrokerProposal: false })
        goToNextRoute()
      },
      label: `ne vous a pas encore fait de proposition`,
    },
  ]

  const choicesOrder = [choicesHasBroker, choicesHasSigned, choicesHasProposition]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={displayNextButton ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Démarches courtier</Title>

      <Sentences>
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!isNil(hasABroker)}
        >
          {choicesHasBroker[hasABroker ? 0 : 1].label}
        </Prompt>
        entamé des démarches avec un courtier
        {hasABroker && (
          <span>
            .
            <Prompt
              onClick={() => {
                setActiveField(1)
              }}
              isFilled={!isNil(signedWithBroker)}
            >
              {choicesHasSigned[signedWithBroker ? 0 : 1].label}
            </Prompt>
            signé avec ce courtier
          </span>
        )}
        {hasABroker && signedWithBroker ? (
          <span>
            qui
            <Prompt
              onClick={() => {
                setActiveField(2)
              }}
              isFilled={!isNil(hasBrokerProposal)}
            >
              {choicesHasProposition[hasBrokerProposal ? 0 : 1].label}
            </Prompt>
            proposition.
          </span>
        ) : (
          '.'
        )}
      </Sentences>

      {activeField !== null && <Choices withoutTracking choices={choicesOrder[activeField]} />}
    </StepsTemplate>
  )
}
