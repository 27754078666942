import { TextFieldDefault } from '@pretto/zen/reveal/atoms/textfields/TextFieldDefault/TextFieldDefault'

import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { validateContact } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/validateContact'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { InputList } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/InputList/InputList'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useHistory } from 'react-router'
import { useDebounce } from 'use-debounce'

export const NotaryContactStep: React.FC = () => {
  const { hasComortgagor, maturity, notaryContact, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const { phoneProps, emailProps, isEmailValid, isPhoneValid } = validateContact({
    email: notaryContact?.email,
    phone: notaryContact?.phone,
  })

  const nextRouteCondition =
    notaryContact?.lastname && notaryContact?.email && notaryContact?.phone && isEmailValid && isPhoneValid

  const [debounceValidationProps] = useDebounce({ phoneProps, emailProps }, 1000)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const inputsConfig = [
    {
      inputProps: { placeholder: 'Nom', type: 'text' },
      value: notaryContact?.lastname || '',
      onChange: (value: string) => setContext({ notaryContact: { ...notaryContact, lastname: value } }),
    },
    {
      inputProps: { placeholder: 'Adresse email', type: 'email' },
      value: notaryContact?.email || '',
      onChange: (value: string) => setContext({ notaryContact: { ...notaryContact, email: value } }),
      ...debounceValidationProps.emailProps,
    },
    {
      inputProps: { placeholder: 'Numéro de téléphone', type: 'tel' },
      value: notaryContact?.phone || '',
      onChange: (value: string) => setContext({ notaryContact: { ...notaryContact, phone: value } }),
      ...debounceValidationProps.phoneProps,
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Coordonnées de votre notaire</Title>

      <InputList>
        {inputsConfig.map((inputProps, index) => (
          <TextFieldDefault key={index} {...inputProps} />
        ))}
      </InputList>
    </StepsTemplate>
  )
}
