import CollapsibleRow from '@pretto/bricks/components/utility/CollapsibleRow'

import { type OptionProps } from '@pretto/zen/reveal/atoms/textfields/Option/Option'
import { InputSearch } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/components/InputSearch/InputSearch'
import { FieldProps } from '@pretto/zen/reveal/types/Field'

import { useEffect, useRef, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'

import * as S from './TextFieldSearch.styles'

export interface TextFieldSearchProps extends Omit<FieldProps, 'inputProps'> {
  isLoading?: boolean
  noResultsMessage?: string
  onSearchChange: (value: string) => void
  optionsList: OptionProps[]
  placeholder: string
  searchValue: string
}

export const TextFieldSearch: React.FC<TextFieldSearchProps> = ({
  isLoading,
  message,
  noResultsMessage,
  onChange,
  onSearchChange,
  optionsList,
  placeholder,
  searchValue,
  state = 'default',
  value,
  ...props
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const optionsListRef = useRef<HTMLUListElement | null>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isFirstOptionFocused, setIsFirstOptionFocused] = useState(false)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    optionsListRef.current?.addEventListener('keydown', handleOptionsListKeyDown, true)

    return () => {
      optionsListRef.current?.removeEventListener('keydown', handleOptionsListKeyDown, true)
    }
  }, [isOpen])

  const handleClickOutside = (event: MouseEvent) => {
    if (!selectRef.current || !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
      setIsFirstOptionFocused(false)
    }
  }

  const getOptionLabelByValue = (value: string): string | undefined => {
    if (value) {
      return optionsList.find(item => item.value === value)?.label
    }

    return undefined
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter'].includes(event.key)) {
      event.preventDefault()
      inputRef.current?.click()
    }
    if (event.key === 'Tab' && isOpen === true) {
      setIsFirstOptionFocused(true)
    }
    if (event.key === 'ArrowDown' && isOpen === true) {
      setIsFirstOptionFocused(true)
    }
  }

  const handleOptionsListKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpen === true) {
      closeOptionsList()
    }
  }

  const closeOptionsList = () => {
    setIsOpen(false)
    setIsFirstOptionFocused(false)
  }

  const handleSelectOption = (value: string) => {
    onSearchChange(getOptionLabelByValue(value) || '')
    onChange(value)
    closeOptionsList()
    inputRef.current?.blur()
  }

  const handleRemoveSelectedOption = () => {
    onChange('')
    onSearchChange('')
  }

  const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.currentTarget.value)
    setIsOpen(true)
  }

  const inputProps = {
    clearValue: handleRemoveSelectedOption,
    isOpen,
    isValue: true,
    onChange: handleInputValueChange,
    placeholder,
    state,
    value: searchValue,
  }

  const optionsListProps = {
    isFirstOptionFocused,
    noResultsMessage: isLoading ? 'Chargement...' : noResultsMessage,
    onClick: handleSelectOption,
    optionsList,
    selectedOptionValue: value,
  }

  return (
    <S.TextFieldSearch {...props}>
      <S.Select ref={selectRef}>
        <InputSearch
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
          role="searchbox"
          {...inputProps}
        />
        {isOpen && (
          <S.OptionsList
            aria-expanded={isOpen}
            aria-hidden={!isOpen}
            aria-invalid={false}
            ref={optionsListRef}
            role="listbox"
            {...optionsListProps}
          />
        )}
      </S.Select>

      <TransitionGroup>
        {!!message && state !== 'default' && (
          <CollapsibleRow animateIn childComponent={S.Footer}>
            <S.ErrorMessage $state={state}>{message}</S.ErrorMessage>
          </CollapsibleRow>
        )}
      </TransitionGroup>
    </S.TextFieldSearch>
  )
}
