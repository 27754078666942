import type { ApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'

export const extractBanksInfo = (
  solicitedBanks: NonNullable<ApplicationIntroductionQuery['project']>['solicited_banks'] | null | undefined
) => {
  if (!solicitedBanks) return { hasBanksToAvoid: null, banksToAvoid: [] }

  const blockedBanks = solicitedBanks.filter(({ blocked }) => blocked)
  return {
    hasBanksToAvoid: blockedBanks.length > 0 ? true : null,
    banksToAvoid: blockedBanks.map(({ bank_slug, id }) => ({ bank_slug, id })),
  }
}
