import { g } from '@pretto/bricks/components/layout'

import { ExclamationMarkCircleBold, ExclamationMarkTriangleBold, MagnifyingBold, TimesBold } from '@pretto/picto'

import styled, { css } from 'styled-components'

type PictoTypes = 'error' | 'warning' | 'search' | 'clear'

type PictoProps = {
  $isDisplayed: boolean
  $type: PictoTypes
}

export interface InputPictoProps extends React.HTMLAttributes<HTMLButtonElement> {
  type: PictoTypes
}

const PICTOS = {
  error: { picto: ExclamationMarkCircleBold, color: 'error1' },
  warning: { picto: ExclamationMarkTriangleBold, color: 'warning1' },
  search: { picto: MagnifyingBold, color: 'neutral2' },
  clear: { picto: TimesBold, color: 'neutral2' },
}

const TYPES = Object.keys(PICTOS) as PictoTypes[]

export const InputPicto: React.FC<InputPictoProps> = ({ type, ...props }) => (
  <InputPictoContainer tabIndex={type === 'clear' ? 0 : -1} {...props}>
    {TYPES.map(pictoType => (
      <Picto $isDisplayed={pictoType === type} $type={type} as={PICTOS[pictoType].picto} key={pictoType} />
    ))}
  </InputPictoContainer>
)

const InputPictoContainer = styled.button`
  position: relative;
  width: ${g(2)};
  height: ${g(2)};
  flex-shrink: 0;
`

const Picto = styled.svg<PictoProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme, $type }) => theme.colors[PICTOS[$type].color]};
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 300ms ease-in-out;

  ${({ $isDisplayed }) =>
    $isDisplayed &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  ${({ $isDisplayed, $type }) =>
    $isDisplayed &&
    $type === 'clear' &&
    css`
      cursor: pointer;
    `}
`
