import { type TimelineProps } from '@pretto/app/src/SharedComponents/Timeline/Timeline'

import * as S from './IntroductionTemplate.styles'

export type IntroductionTemplateProps = {
  children: React.ReactNode
  isQueryLoading?: boolean
  onNext: () => void
  timeLineProps: TimelineProps
}

export const IntroductionTemplate = ({
  children,
  isQueryLoading = false,
  onNext,
  timeLineProps,
  ...props
}: IntroductionTemplateProps) => (
  <S.Template {...props}>
    <S.StyledTimeline {...timeLineProps} />

    <S.SideIllustration />

    <S.MainContent>
      <S.Grid>
        <S.Content>{children}</S.Content>

        <S.TabletIllustration />

        <S.ButtonContainer>
          <S.NextButton loading={isQueryLoading} onClick={onNext}>
            Suivant
          </S.NextButton>
        </S.ButtonContainer>
      </S.Grid>
    </S.MainContent>
  </S.Template>
)
