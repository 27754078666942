import { breakpoints, g } from '@pretto/bricks/components/layout'

import styled from 'styled-components'

export type TitleProps = {
  children: string
}

export const Title = ({ children, ...props }: TitleProps) => <StyledTitle {...props}>{children}</StyledTitle>

const StyledTitle = styled.div`
  ${({ theme }) => theme.typos.heading5};
  color: ${({ theme }) => theme.colors.neutral1};
  margin: ${g(1)} 0 ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.heading4};
    margin-bottom: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(2)};
  }
`
