import { TEMPLATES } from '@pretto/bricks/app/pre-lead/components/BlockInfoCTA'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import * as S from './styles'

const DECIMALS = 2

export const getInfos = ({
  isPositive,
  loanAmount,
  projectKind,
  propertyPrice,
  range,
  rate,
  savings,
  scoreFundability,
  totalCost,
  typology,
}) => {
  const ctaPurchaseRenegotiation =
    typology === 'client'
      ? {
          bgColor: 'neutral4',
          buttonText: 'Suivant',
          href: '/',
          text: 'Complétez vos informations et remplissez votre dossier de demande de financement en ligne et à votre rythme.',
          title: 'Complétez vos informations',
        }
      : {
          ...TEMPLATES.appointment,
          bgColor: 'neutral4',
          buttonText: 'Suivant',
          buttonTextMobile: 'Suivant',
          href: '/simulation/subscribe?kind=appointment_details',
        }

  switch (projectKind) {
    case 'capacity':
      return {
        cta: {
          ...TEMPLATES.certificate,
          bgColor: 'neutral4',
          buttonText: 'Suivant',
          buttonTextMobile: 'Suivant',
          href: typology === 'client' ? '/' : '/simulation/subscribe?kind=certificate_details',
        },
        detailsText: 'Détail du calcul',
        infos: [
          {
            label: 'Estimation basse',
            value: range[0],
          },
          {
            label: 'Estimation haute',
            value: range[1],
          },
        ],
        mainInfo: {
          title: 'Vous pouvez acheter un bien d’une valeur de :',
          value: formatNumber(propertyPrice, { suffix: '€' }),
        },
      }
    case 'purchase':
      return {
        cta: ctaPurchaseRenegotiation,
        detailsText: 'Détail de l’offre',
        infos: [
          {
            label: 'Financement',
            value: <S.Fundability $color={scoreFundability.color}>{scoreFundability.label}</S.Fundability>,
          },
          {
            label: 'Montant emprunté',
            value: formatNumber(loanAmount, { suffix: '€' }),
          },
          {
            label: 'Coût total du crédit',
            value: formatNumber(totalCost, { suffix: '€' }),
          },
        ],
        mainInfo: {
          title: 'La meilleure offre',
          value: formatNumber(rate, { decimals: DECIMALS, suffix: '%' }),
        },
      }
    case 'renegotiation':
      return {
        cta: ctaPurchaseRenegotiation,
        detailsText: 'Détail de l’offre',
        infos: [
          {
            label: 'Montant emprunté',
            value: formatNumber(loanAmount, { suffix: '€' }),
          },
          {
            color: isPositive ? 'primary1' : 'error1',
            label: isPositive ? 'Montant économisé' : 'Surcoût',
            value: formatNumber(Math.abs(savings), { suffix: '€' }),
          },
        ],
        mainInfo: {
          title: 'La meilleure offre',
          value: formatNumber(rate, { decimals: DECIMALS, suffix: '%' }),
        },
      }
    default:
      return null
  }
}

export const getScoreWithColors = ({ variant, ...score } = {}) => {
  switch (variant) {
    case 'success':
      return {
        ...score,
        color: 'primary2',
        details: {
          bgColor: 'primary4',
          color: 'primary1',
        },
      }
    case 'warning':
      return {
        ...score,
        color: 'error2',
        details: {
          bgColor: 'error2',
          color: 'error1',
        },
      }
    default:
      return {
        ...score,
        details: {},
      }
  }
}
