import { g } from '@pretto/bricks/components/layout'

import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import { CheckBold } from '@pretto/picto'

import styled from 'styled-components'

type OptionProps = {
  $isSelected?: boolean
}

export const Option = styled.li<OptionProps>`
  ${({ theme }) => theme.typos.body4};
  ${({ theme }) => theme.colors.neutral1};
  position: relative;
  display: flex;
  align-items: center;
  padding: ${g(1)} ${({ $isSelected }) => g(2, $isSelected ? -4 : 0)} ${g(1)} ${g(2)};
  width: 100%;
  border-radius: ${g(1 / 2)};
  text-align: start;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: ${g(1)};
  flex-shrink: 0;
  outline-offset: 1px;

  &:before {
    ${transition({ propertyName: 'background-color' }, { propertyName: 'clip-path' })};
    bottom: 0;
    content: '';
    clip-path: inset(0 100% 0 0);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:focus:before,
  &:hover:before {
    background-color: ${({ theme }) => theme.colors.neutral4};
    clip-path: inset(0 0% 0 0 round ${g(1 / 2)});
  }
`

export const Label = styled.span`
  position: relative;
  z-index: 1;
`

export const SelectedPicto = styled(CheckBold)`
  position: relative;
  z-index: 1;
  width: ${g(2)};
  height: ${g(2)};
  flex-shrink: 0;
  margin-left: auto;
`
