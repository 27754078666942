import { TextFieldDefault } from '@pretto/zen/reveal/atoms/textfields/TextFieldDefault/TextFieldDefault'

import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { validateContact } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/validateContact'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { InputList } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/InputList/InputList'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useDebounce } from 'use-debounce'

type EmailError = { state: 'error'; message: string }

export const ComortgagorStep: React.FC = () => {
  const { comortgagorContact, hasComortgagor, isComortgagorInvited, maturity, persistComortgagor, setContext } =
    useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const { accounts } = useUser()
  const trackAction = useTracking()

  const [emailErrorProps, setEmailErrorProps] = useState<EmailError | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isComortgagorInvited && comortgagorContact?.email) {
      setContext({ comortgagorContact: { ...comortgagorContact, email: comortgagorContact.email as string } })
    }
  }, [])

  const { phoneProps, emailProps, isEmailValid, isPhoneValid } = validateContact({
    email: comortgagorContact?.email,
    phone: comortgagorContact?.phone,
    emailsAssigned: [accounts[0].email],
  })

  const [debounceValidationProps] = useDebounce({ phoneProps, emailProps }, 1000)

  const nextRouteCondition =
    comortgagorContact?.firstname &&
    comortgagorContact?.lastname &&
    comortgagorContact?.email &&
    comortgagorContact?.phone &&
    isEmailValid &&
    isPhoneValid

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = async () => {
    if (!comortgagorContact) return

    setIsLoading(true)
    try {
      const { state } = await persistComortgagor()

      if (state === 'error') {
        setEmailErrorProps({ state: 'error', message: `Cet email est déjà utilisé` })
        return
      }

      trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
      goToNextRoute()
    } catch (error) {
      setEmailErrorProps({ state: 'error', message: `Une erreur est survenue. Veuillez réessayer.` })
    } finally {
      setIsLoading(false)
    }
  }

  const inputsConfig = [
    {
      inputProps: { placeholder: 'Prénom', type: 'text' },
      value: comortgagorContact?.firstname || '',
      onChange: (value: string) => setContext({ comortgagorContact: { ...comortgagorContact, firstname: value } }),
    },
    {
      inputProps: { placeholder: 'Nom', type: 'text' },
      value: comortgagorContact?.lastname || '',
      onChange: (value: string) => setContext({ comortgagorContact: { ...comortgagorContact, lastname: value } }),
    },
    {
      inputProps: { placeholder: 'Adresse email', type: 'email' },
      value: comortgagorContact?.email || '',
      onChange: (value: string) => {
        setEmailErrorProps(null)
        setContext({ comortgagorContact: { ...comortgagorContact, email: value } })
      },
      ...debounceValidationProps.emailProps,
      ...emailErrorProps,
      isDisabled: isComortgagorInvited,
    },
    {
      inputProps: { placeholder: 'Numéro de téléphone', type: 'tel' },
      value: comortgagorContact?.phone || '',
      ...debounceValidationProps.phoneProps,
      onChange: (value: string) => setContext({ comortgagorContact: { ...comortgagorContact, phone: value } }),
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
      isLoading={isLoading}
    >
      <Title>Coordonnées de votre co-emprunteur</Title>

      <InputList>
        {inputsConfig.map((inputProps, index) => (
          <TextFieldDefault key={index} {...inputProps} />
        ))}
      </InputList>
    </StepsTemplate>
  )
}
