import { g } from '@pretto/bricks/components/layout'

import { OptionsList as OptionsListComponent } from '@pretto/zen/reveal/atoms/textfields/OptionsList/OptionsList'
import { InputState } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/types/inputStates.types'

import styled from 'styled-components'

const STATE_COLOR = {
  default: 'neutral1',
  error: 'error1',
  warning: 'warning1',
}

type StateProps = {
  $state: InputState
}

export const TextFieldSearch = styled.div``

export const Select = styled.div`
  position: relative;
`

export const OptionsList = styled(OptionsListComponent)`
  position: absolute;
  top: calc(100% + ${g(1)});
  left: 0;
  white-space: nowrap;
  width: 100%;
`

export const ErrorMessage = styled.div<StateProps>`
  ${({ theme }) => theme.typos.caption};
  color ${({ theme, $state }) => theme.colors[STATE_COLOR[$state]]};
`

export const Footer = styled.div`
  padding-top: ${g(1)};
`
