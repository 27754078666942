const allAccess = {
  hasSaleAgreementSigned: true,
  hasNoSaleAgreementSigned: true,
  isHot: true,
  isCold: true,
  hasComortgagor: true,
  hasNoComortgagor: true,
}

const hotOnly = { ...allAccess, isCold: false }

const withComortgagorOnly = { ...allAccess, hasNoComortgagor: false }

const signedAgreementOnly = { ...allAccess, hasNoSaleAgreementSigned: false }

export const STEPS_ACCESS = [
  { path: '/application/introduction/sollicited-broker', profileAccess: hotOnly },
  { path: '/application/introduction/sollicited-broker-rates', profileAccess: hotOnly },
  { path: '/application/introduction/banks', profileAccess: allAccess },
  { path: '/application/introduction/avoid-banks', profileAccess: allAccess },
  { path: '/application/introduction/project', profileAccess: withComortgagorOnly },
  { path: '/application/introduction/notary', profileAccess: signedAgreementOnly },
  { path: '/application/introduction/notary/contact', profileAccess: signedAgreementOnly },
  { path: '/application/introduction/banks/overdraft', profileAccess: allAccess },
  { path: '/application/introduction/banks/joint-account', profileAccess: withComortgagorOnly },
  { path: '/application/introduction/banks/bank-accounts', profileAccess: allAccess },
]
