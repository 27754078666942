import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { isNil } from 'lodash'
import { useState } from 'react'
import { useHistory } from 'react-router'

export const JointAccountStep: React.FC = () => {
  const { hasComortgagor, hasJointAccount, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const nextRouteCondition = !isNil(hasJointAccount)

  const [activeField, setActiveField] = useState<number | null>(nextRouteCondition ? null : 0)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const choicesHasJointAccount = [
    {
      key: 'hasJointAccount',
      isSelected: hasJointAccount === true,
      onClick: () => {
        setContext({ hasJointAccount: true })
        setActiveField(null)
        goToNextRoute()
      },
      label: `Avez`,
    },
    {
      key: 'hasNoJointAccount',
      isSelected: hasJointAccount === false,
      onClick: () => {
        setContext({ hasJointAccount: false, jointAccounts: [] })
        setActiveField(null)
        goToNextRoute()
      },
      label: `n'avez pas`,
    },
  ]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? () => handleNext() : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Vos comptes bancaires</Title>

      <Sentences>
        Vous et votre co-emprunteur
        <Prompt onClick={() => setActiveField(0)} isFilled={!isNil(hasJointAccount)}>
          {choicesHasJointAccount[hasJointAccount ? 0 : 1].label.toLocaleLowerCase()}
        </Prompt>
        un ou plusieurs comptes joints.
      </Sentences>

      {activeField === 0 && <Choices withoutTracking choices={choicesHasJointAccount} />}
    </StepsTemplate>
  )
}
