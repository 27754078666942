import { NAV_ITEMS } from '@pretto/app/src/SharedContainers/Header/config/navigationItems'

export const getNavNotClient = projectKind => {
  switch (projectKind) {
    case 'purchase':
      return {
        desktop: [NAV_ITEMS.modifySimulatorPurchase, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.modifySimulatorPurchase, NAV_ITEMS.faq],
      }
    case 'renegotiation':
      return {
        desktop: [NAV_ITEMS.modifySimulatorRenegociaton, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.modifySimulatorRenegociaton, NAV_ITEMS.faq],
      }
    case 'capacity':
    default:
      return {
        desktop: [NAV_ITEMS.modifySimulatorCapacity, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.modifySimulatorCapacity, NAV_ITEMS.faq],
      }
  }
}

export const getNavigationItemsList = ({
  hasFeatureAccess,
  typology,
  isDashboardPageReached,
  status,
  projectKind,
  isPriceSubmitted,
}) => {
  if (typology !== 'client') {
    return getNavNotClient(projectKind)
  }

  if (!isDashboardPageReached) {
    return {
      desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.faq],
      tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.faq],
    }
  }

  if (['analysis', 'confirmation', 'negotiation', 'agreement', 'appointment', 'signature'].includes(status)) {
    if (hasFeatureAccess(['BUYOUT'])) {
      return {
        desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.documents, NAV_ITEMS.faq],
        tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.documents],
      }
    }

    return {
      desktop: [
        isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity,
        NAV_ITEMS.dashboard,
        NAV_ITEMS.documents,
        NAV_ITEMS.faq,
      ],
      tablet: [isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity, NAV_ITEMS.dashboard, NAV_ITEMS.documents],
    }
  }

  if (hasFeatureAccess(['BUYOUT'])) {
    return {
      desktop: [NAV_ITEMS.dashboard, NAV_ITEMS.folder, NAV_ITEMS.faq],
      tablet: [NAV_ITEMS.dashboard, NAV_ITEMS.folder],
    }
  }

  return {
    desktop: [
      isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity,
      NAV_ITEMS.dashboard,
      NAV_ITEMS.folder,
      NAV_ITEMS.faq,
    ],
    tablet: [isPriceSubmitted ? NAV_ITEMS.simulator : NAV_ITEMS.capacity, NAV_ITEMS.dashboard, NAV_ITEMS.folder],
  }
}
