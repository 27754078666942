/* eslint-disable max-lines */
import SimulationBanner from '@pretto/bricks/app/simulator/components/SimulationBanner'
import { BlockRateDoesntCountType } from '@pretto/bricks/app/simulator/components/SimulationContent/children/BlockRateDoesntCount/BlockRateDoesntCount'
import SimulationPage from '@pretto/bricks/app/simulator/pages/SimulationPage'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { ERROR_TYPES, getAuthErrorMessage } from '@pretto/bricks/core/utility/errors'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import { temporal } from '@pretto/bricks/core/utility/temporal'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import getMalusFromFlags from '@pretto/app/src/Errors/lib/getMalusFromFlags'
import { useEmailField } from '@pretto/app/src/Form/lib/useField'
import Front from '@pretto/app/src/Helpers/frontchat'
import { ScoringContext } from '@pretto/app/src/Scoring/contexts/ScoringContext/ScoringContext'
import Header from '@pretto/app/src/SharedContainers/Header'
import { getIsPriceSubmitted } from '@pretto/app/src/SharedContainers/Header/lib/getIsPriceSubmitted'
import { getNavigationItemsList } from '@pretto/app/src/SharedContainers/Header/lib/simulatorResultsNav'
import { MutualizedAgenda } from '@pretto/app/src/SharedContainers/MutualizedAgenda/MutualizedAgenda'
import { Occurrence } from '@pretto/app/src/SharedContainers/MutualizedAgenda/types/occurrence'
import SubscribeConsumer from '@pretto/app/src/SharedContainers/Subscribe'
import OptimizePage from '@pretto/app/src/Simulation/Containers/OptimizePage'
import * as bankCriteriasMatrices from '@pretto/app/src/Simulation/Containers/ResultsPage/config/bankCriteriasMatrices'
import { formatBankCriteria } from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/formatBankCriteria'
import {
  getBlockInfoByMaturity,
  getBookedBlockProps,
} from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoByMaturity'
import { ResultsPageContent } from '@pretto/app/src/Simulation/Containers/ResultsPage/views/ResultsPageContent/ResultsPageContent'
import { LOCAL_AGENDA_VISIBLE_NAME } from '@pretto/app/src/Simulation/config/agenda'
import { calcSavings } from '@pretto/app/src/Simulation/lib/calculations'
import filterResultsByDuration from '@pretto/app/src/Simulation/lib/filterResultsByDuration'
import getLoanDurationsFromResults from '@pretto/app/src/Simulation/lib/getLoanDurationsFromResults'
import getScoreDetails from '@pretto/app/src/Simulation/lib/getScoreDetails'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ADVISOR, BOOKING, PROJECT } from '@pretto/app/src/apollo'
import { AdrenaleadIframe } from '@pretto/app/src/components/AdrenaleadIframe/AdrenaleadIframe'
import { getItem, removeItem } from '@pretto/app/src/config/itemStorage'
import { MONTHS_IN_YEAR } from '@pretto/app/src/lib/constants'
import useLanguageInfo from '@pretto/app/src/lib/i18n/useLanguageInfoDialog'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import {
  Bookmark,
  Burden,
  Calendar,
  Clock,
  Coins,
  Crane,
  House,
  Percent,
  Phone,
  Plan,
  WorkingMan,
  ZeroPercent,
} from '@pretto/picto'

import { useApolloClient, useQuery } from '@apollo/client'
import { find, get, max, min, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

const MIN_POSSIBLE_SAVINGS = -100000
const MIN_SAVINGS = 5000

const GreenZeroPercent = styled(ZeroPercent)`
  color: ${({ theme }) => theme.colors.primary1};
`

const ResultsPage = ({
  dealValue,
  duration,
  brokerageFees,
  editableFields,
  fetchOptimizations,

  isOptimizing,
  isProjectChanging,
  onDurationChange,

  onOptimize,
  onOptimizeCancel,
  onOptimizeChoose,

  onProjectChange,
  onProjectChangeCancel,
  onProjectChangeSave,
  onProjectEdit,
  onProjectValidate,
  onUpdateProject,

  onSignUp,

  onValidateDefaultDuration,

  project,
  results,
  shouldRefetchBooking,
  simulationMedium,
}) => {
  const { isLoggedIn, requestGoogleConnect } = useAuth()
  const client = useApolloClient()

  const { push: historyPush } = useHistory()

  const {
    projectID,
    typology,
    hasFeatureAccess,
    isDashboardPageReached,
    status,
    advisor,
    next_booking = {},
    scoreZeroMinute,
  } = useUser()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isTimelineOpen, setIsTimelineOpen] = useState(false)
  const [isSaveOpen, setIsSaveOpen] = useState(false)
  const [isScoreDetailsOpen, setIsScoreDetailsOpen] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [bookingInfos, setBookingInfos] = useState({ isLoading: true })
  const [isPriceChanging, setIsPriceChanging] = useState(false)
  const [changes, setChanges] = useState({})
  const [selectedDuration, setSelectedDuration] = useState(null)
  const [isMutualizedAgendaOpen, setIsMutualizedAgendaOpen] = useState(
    Boolean(getItem(LOCAL_AGENDA_VISIBLE_NAME) && !next_booking)
  )

  const trackAction = useTracking()

  const trustpilotData = useTrustpilotData()

  const isOneOffer = project.project_kind === 'purchase'
  const maxResults = isOneOffer ? 1 : 3

  const projectQuery = useQuery(PROJECT)
  const isPriceSubmitted = getIsPriceSubmitted(projectQuery)

  const isAppointmentAvailable = !hasFeatureAccess(['NO_APPOINTMENT'])

  const {
    facts: { delegation_insurance_rate: delegationInsuranceRate, insurance_rate: insuranceRate },
    renegotiation: {
      facts: { remaining_interests: remainingInterests, remaining_duration: remainingDuration },
      remaining_principal: remainingPrincipal,
    },
  } = project

  const repurchaseAlert = results.reduce(
    (previous, current) => ({
      isShown: previous.isShown && current.financing.savings < MIN_SAVINGS,
      minSavings: current.financing.savings > previous.minSavings ? current.financing.savings : previous.minSavings,
    }),
    { isShown: project.project_kind === 'renegotiation', minSavings: MIN_POSSIBLE_SAVINGS }
  )

  const insuranceSavings = calcSavings(insuranceRate, delegationInsuranceRate, remainingPrincipal, remainingDuration)

  const { isMobile } = useBreakpoint()

  const { isLanguageInfoOpen, closeLanguageInfo } = useLanguageInfo()

  useEffect(() => {
    Front(isMobile && !isTimelineOpen ? 'hide' : 'show')
  }, [isMobile, isTimelineOpen])

  useEffect(() => {
    trackSimulationResults()

    // this allows zou to create a project for prospects and preleads
    if (typology !== 'client') {
      onValidateDefaultDuration()
    }
  }, [])

  useEffect(() => {
    const getHasBooking = async () => {
      const { data } = await client.query({ query: BOOKING })
      const hasBooking = Boolean(data.next_booking)

      if (!hasBooking) {
        return { isLoading: false }
      }

      const { data: advisorData } = await client.query({ query: ADVISOR })
      const date = temporal(data.next_booking.start).format('dddd LL [à] HH[h]mm')

      return { isLoading: false, name: advisorData.advisor.name, start: date }
    }

    if (typology === 'client' || shouldRefetchBooking) {
      getHasBooking().then(result => setBookingInfos(result))
    }
  }, [typology, shouldRefetchBooking])

  const computedDuration = selectedDuration !== null ? selectedDuration : duration

  const handleCardClick = () => trackAction('SIMULATION_RESULT_DETAILS_VIEWED')

  const handleOpenDialog = () => {
    trackAction('SIMULATION_RESULT_MODIFY_BUTTON_CLICKED')
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => setIsDialogOpen(false)

  const handleModify = () => {
    onProjectEdit()

    trackAction('SIMULATION_RESULTS_EDIT_CLICKED')
  }

  const handleClickRow = () => setIsPriceChanging(true)

  const handleKeyPressRowChanges = e => {
    if (e.key === 'Enter') {
      handleValidateRowChanges()
    }
  }

  const handleValidateRowChanges = () => {
    if (
      changes['purchase.property_price'] === 0 ||
      changes['purchase.build_price'] === 0 ||
      changes['purchase.land_price'] === 0
    ) {
      // eslint-disable-next-line no-alert
      alert('Veuillez renseigner une valeur pour le prix.')
      return false
    }
    trackAction('SIMULATION_RESULTS_PRICES_CHANGED')

    if (changes['purchase.property_price'])
      trackAction('SIMULATION_RESULTS_PRICE_CHANGED', { simulation_results_price_slug: 'propertyPrice' })
    if (changes['purchase.build_price'])
      trackAction('SIMULATION_RESULTS_PRICE_CHANGED', { simulation_results_price_slug: 'constructionCost' })
    if (changes['purchase.land_price'])
      trackAction('SIMULATION_RESULTS_PRICE_CHANGED', { simulation_results_price_slug: 'landCost' })
    if (changes['purchase.works_price'])
      trackAction('SIMULATION_RESULTS_PRICE_CHANGED', { simulation_results_price_slug: 'worksCost' })
    if (changes.contribution)
      trackAction('SIMULATION_RESULTS_PRICE_CHANGED', { simulation_results_price_slug: 'contribution' })
    onProjectChange(changes, 'editable_fields')
    setIsPriceChanging(false)
    setChanges({})
    setIsDialogOpen(false)
  }

  const handleDurationChange = duration => {
    trackSimulationResults(duration)
  }

  const handleAfterChange = () => {
    if (selectedDuration && selectedDuration !== duration) {
      onDurationChange(selectedDuration, 'sliders', handleDurationChange)
    }

    setSelectedDuration(null)
  }

  const handleChangeRow = path => value => {
    if (!isPriceChanging) {
      setIsPriceChanging(true)
    }

    // Convert empty fields to zeros
    const newVal = value || 0

    setChanges(changes => ({ ...changes, [path]: newVal }))
  }

  const handleTrackCard = cardKind =>
    trackAction('SIMULATION_RESULTS_PARAMETER_CARD_CLICKED', { simulation_results_parameter_card_kind: cardKind })

  const trackSimulationResults = (duration = computedDuration) => {
    const trackingName = 'SIMULATION_RESULTS_PAGE_VIEWED'
    const cards = filterResultsByDuration(results, duration)
    const currentResult = cards[0] || {}

    const { flags } = getSelectedResult()
    const score = getScoreDetails(flags, cards.length)

    const trackingOptions = {
      simulation_results_best_rate: get(currentResult, 'financing.mainLoansRate'),
      simulation_results_cards: cards.map(
        ({
          bank: { id },
          financing: { applicationFees, creditCosts, guarantyFees, mainLoansRate, payment, savings },
        }) => ({
          application_fees: applicationFees,
          bank: id,
          guaranty_fees: guarantyFees,
          monthly_payment: payment,
          mortgage_cost: creditCosts,
          mortgage_savings: savings,
          notary_fees: get(project, 'facts.notary_fees'),
          rate: mainLoansRate,
        })
      ),
      simulation_results_contribution_amount: get(project, 'contribution'),
      simulation_results_indebtedness_rate_kind: get(currentResult, 'scoreIndebtednessRate.kind'),
      simulation_results_indebtedness_rate_value: get(currentResult, 'scoreIndebtednessRate.value'),
      simulation_results_kind: project.project_kind,
      simulation_results_loan_amount: get(currentResult, 'financing.loanAmount'),
      simulation_results_loan_duration: duration,
      simulation_results_medium: simulationMedium.current,
      simulation_results_value: dealValue,
      value: dealValue,
    }
    if (project.project_kind === 'purchase') {
      Object.assign(trackingOptions, {
        simulation_results_good_amount: get(project, 'purchase.property_price'),
        simulation_results_works_amount: get(project, 'purchase.works_price'),
        simulation_results_fundability_difficulty: score.label,
      })
    } else {
      Object.assign(trackingOptions, {
        simulation_results_remaining_principal: get(project, 'renegotiation.remaining_principal'),
      })
    }

    trackAction(trackingName, trackingOptions)

    if (repurchaseAlert.isShown) {
      trackAction('SIMULATION_RESULTS_INSURANCE_MESSAGE_SHOWN', {
        simulation_results_insurance_savings_amount: insuranceSavings,
        simulation_results_minimum_mortgage_savings_amount: get(currentResult, 'financing.savings'),
      })
    }

    if (project.solicited_banks.length > 0) {
      trackAction('SIMULATION_RESULTS_RATEDOESNTCOUNT_MESSAGE_SHOWN')
    }
  }

  const changeHandler = key => val => {
    switch (key) {
      case 'duration':
        setSelectedDuration(val * MONTHS_IN_YEAR)
        break
      case 'payment': {
        const selectedDuration = find(results, ['financing.payment', val]).financing.loanDuration
        setSelectedDuration(selectedDuration)
        break
      }
      default:
        break
    }
  }
  const trackAndValidate = (...trackingParams) => {
    if (typology !== 'client') {
      trackAction(...trackingParams)
      onProjectValidate()
    }
  }

  const handleSubscribeFromScore = () => {
    setIsScoreDetailsOpen(false)
    trackAndValidate('SIMULATION_RESULTS_SCORE_BUTTON_CLICKED')
  }

  const handleOpenScoreDetails = () => {
    trackAction('SIMULATION_RESULTS_INFORMATION_ICON_CLICKED', { simulation_results_information_icon_kind: 'score' })
    setIsScoreDetailsOpen(true)
  }

  const handleCloseScoreDetails = () => setIsScoreDetailsOpen(false)

  const scoreDetailsProps = () => {
    const result = getSelectedResult()
    const malus = getMalusFromFlags(result.flags, { ...result, project })
    const cards = filterResultsByDuration(results, computedDuration)

    return {
      ...getScoreDetails(result.flags, cards.length),
      malus,
      onSubscribe: handleSubscribeFromScore,
      showButton: !isLoggedIn,
    }
  }

  const handleClickScoreSeeMore = () => {
    handleTrackCard('score')
    handleOpenScoreDetails()
  }

  const resultsCards = () => {
    const { flags } = getSelectedResult()
    const cards = filterResultsByDuration(results, computedDuration)
    const score = getScoreDetails(flags, cards.length)

    const cardsProps = cards.slice(0, maxResults).map(result => {
      const {
        bank: { allowLoanTransfer, allowFlexiblePayments },
        financing: {
          applicationFees,
          bridge,
          creditCosts,
          guarantyFees,
          interests,
          loanAmount,
          loanDuration,
          mainLoansRate,
          payment,
          penaltyFees,
          ptz,
          savings,
        },
        scoreIndebtednessRate: { value: indebtednessRate },
      } = result

      return {
        cardData: {
          applicationFees,
          bridge,
          brokerageFees,
          guarantyFees,
          indebtednessRate,
          interests,
          loanAmount,
          loanDuration,
          modularity: allowFlexiblePayments,
          notaryFees: get(project, 'facts.notary_fees'),
          payment,
          ptz,
          rate: mainLoansRate,
          remainingInterests,
          repurchaseFees: penaltyFees,
          savings,
          score: { ...score, onClickScoreSeeMore: score.label === 'facile' ? null : handleClickScoreSeeMore },
          totalCost: creditCosts,
          transferability: allowLoanTransfer,
        },
      }
    })
    return cardsProps
  }

  const handleSimulateInsurance = () => {
    trackAction('SIMULATION_RESULTS_INSURANCE_CLICKED')
  }

  const trackLegalLink = source => () => {
    trackAction('SIMULATION_RESULTS_LEGAL_LINKS_CLICKED', { simulation_results_legal_link: source })
  }

  const handleCloseTimeline = () => {
    setIsTimelineOpen(false)
  }

  const handleOpenTimeline = () => {
    setIsTimelineOpen(true)
  }

  const handleOpenSave = () => {
    trackAction('SIMULATION_RESULT_SAVE_BUTTON_CLICKED')
    setIsSaveOpen(true)
  }

  const handleCloseSave = () => {
    setIsSaveOpen(false)
  }

  const secondActionProps = () => {
    switch (typology) {
      case 'prospect':
        return { onClick: handleOpenSave, picto: Bookmark, text: 'Sauvegarder' }
      case 'preLead':
        return { onClick: handleAppointmentClick, picto: Phone, text: 'Prendre RDV' }
      default:
        return null
    }
  }

  const handleOpenMutualizedAgenda = () => {
    trackAction('APPOINTMENT_MUTUALIZED_AGENDA_CLICKED')
    setIsMutualizedAgendaOpen(true)
  }

  const handleCloseMutualizedAgenda = () => {
    removeItem(LOCAL_AGENDA_VISIBLE_NAME)
    setIsMutualizedAgendaOpen(false)
  }

  const blockRateDoesntCountProps = () => {
    const cards = filterResultsByDuration(results, computedDuration)

    if (project.solicited_banks.every(({ rate }) => typeof rate !== 'number')) {
      return null
    }

    if (project.solicited_banks.some(({ rate }) => rate < cards[0].financing.mainLoansRate)) {
      return {
        onChallengeButtonClick() {
          trackAction('SIMULATION_RESULT_ALTERNATIVE_BUTTON_CLICKED', {
            simulation_result_alternative_button_type: 'subscribe',
          })
          handleCardCTAClick()
        },
        onInsuranceButtonClick() {
          trackAction('SIMULATION_RESULT_ALTERNATIVE_BUTTON_CLICKED', {
            simulation_result_alternative_button_type: 'insurance',
            simulation_result_alternative_type: BlockRateDoesntCountType.better,
          })
        },
        type: BlockRateDoesntCountType.better,
      }
    }

    return {
      onChallengeButtonClick() {
        trackAction('SIMULATION_RESULT_ALTERNATIVE_BUTTON_CLICKED', {
          simulation_result_alternative_button_type: 'subscribe',
          simulation_result_alternative_type: BlockRateDoesntCountType.worst,
        })
        handleCardCTAClick()
      },
      type: BlockRateDoesntCountType.worst,
    }
  }

  const contentComponent = () => {
    const projectKind = project.project_kind

    if (projectKind === 'renegotiation') {
      return null
    }

    const cards = filterResultsByDuration(results, computedDuration)
    const steps = [
      { href: `/project/${projectKind}/introduction`, title: 'Votre projet' },
      { href: `/project/${projectKind}/lodging`, title: 'Vos informations' },
      { href: '/simulation', onClick: handleCloseTimeline, title: 'Résultats' },
    ]

    const {
      bank: { criterias },
      financing: {
        applicationFees,
        bridge,
        creditCosts,
        guarantyFees,
        interests,
        loanAmount,
        loanDuration,
        mainLoansRate,
        payment,
        ptz,
        ecoPtz,
      },
      scoreIndebtednessRate: { value: indebtednessRate },
    } = cards[0]

    const { buildPrice, contribution, landPrice, propertyPrice, worksPrice } = editableFields.reduce(
      (previous, { key, value }) => ({ ...previous, [key]: value }),
      {}
    )

    const resultsPageContentProps = {
      blocCacheProps: { loginUrl: typology !== 'prospect' ? '/' : null, onClick: handleCardCTAClick },
      blocResultatProps: {
        bankCriterias: [
          formatBankCriteria(bankCriteriasMatrices.matriceInsurance, criterias.scoringInsurance),
          formatBankCriteria(bankCriteriasMatrices.matriceAverageResponseTime, criterias.scoringAverageResponseTime),
          formatBankCriteria(bankCriteriasMatrices.matriceRse, criterias.scoringRse),
          formatBankCriteria(bankCriteriasMatrices.matriceDigitization, criterias.scoringDigitization),
        ],
        bankIndex: 1,
        buttonLabel: typology !== 'prospect' ? 'Suivant' : 'Découvrir la banque',
        details: [
          { label: 'Taux', value: formatNumber(mainLoansRate, { suffix: '%' }) },
          { label: 'Montant emprunté', value: formatNumber(loanAmount, { suffix: '€' }) },
          { label: 'Coût total du crédit', value: formatNumber(creditCosts, { suffix: '€' }) },
        ],
        offerCriterias: [
          formatBankCriteria(bankCriteriasMatrices.matriceIra, criterias.scoringIra),
          formatBankCriteria(bankCriteriasMatrices.matriceModularity, criterias.scoringModularity),
          formatBankCriteria(bankCriteriasMatrices.matriceReportDeadline, criterias.scoringReportDeadline),
        ],
        onButtonClick: handleCardCTAClick,
        onDetailsButtonClick() {
          trackAction('SIMULATION_RESULT_DETAILS_VIEWED')
          setIsDetailsOpen(true)
        },
        payment: formatNumber(payment, { suffix: '€' }),
      },
      blockInfoProps:
        bookingInfos.start || bookingInfos.name
          ? { ...getBookedBlockProps(bookingInfos, historyPush), cta: null }
          : null,
      blockRateDoesntCountProps: blockRateDoesntCountProps(),
      date: temporal(),
      financingBanksCount: cards.length,
      footerInfosProps: { count: trustpilotData.numberOfReviews, rate: trustpilotData.score },
      hasAppointment: !!bookingInfos.start,
      isDetailsOpen,
      isLoggedIn,
      isOnboardingBlockVisible: typology === 'client' && !isDashboardPageReached,
      isTimelineOpen,
      isTimelineVisible: typology === 'prospect',
      modaleResultProps: {
        buttonLabel: typology !== 'prospect' ? 'Suivant' : 'Découvrir la banque',
        details: [
          { label: 'Taux', value: formatNumber(mainLoansRate, { suffix: '%' }) },
          { label: 'Montant emprunté', value: formatNumber(loanAmount, { suffix: '€' }) },
          { label: 'Coût total du crédit', value: formatNumber(creditCosts, { suffix: '€' }) },
        ],
        payment: formatNumber(payment, { suffix: '€' }),
        sections: [
          [
            {
              entries: [
                propertyPrice && {
                  label: 'Prix du bien',
                  picto: House,
                  value: formatNumber(propertyPrice, { suffix: '€' }),
                },
                landPrice && { label: 'Prix du terrain', picto: Plan, value: formatNumber(landPrice, { suffix: '€' }) },
                buildPrice && {
                  label: 'Prix de la construction',
                  picto: Crane,
                  value: formatNumber(buildPrice, { suffix: '€' }),
                },
                worksPrice && {
                  label: 'Prix des travaux',
                  picto: WorkingMan,
                  value: formatNumber(worksPrice, { suffix: '€' }),
                },
                bridge?.remaining > 0 && {
                  label: 'Rachat de CRD',
                  tooltip:
                    'Il s’agit du montant racheté correspondant au capital restant dû (CRD) de votre prêt immobilier actuel.',
                  type: 'plus',
                  value: formatNumber(bridge.remaining, { suffix: '€' }),
                },
                {
                  label: 'Frais de notaire',
                  tooltip: 'Taxes collectées par le notaire pour le compte de l’État (en fonction du projet).',
                  type: 'plus',
                  value: formatNumber(project.facts.notary_fees, { decimals: 0, suffix: '€' }),
                },
                {
                  label: 'Frais de garantie',
                  tooltip:
                    'Montant dû à l’organisme de garantie qui assure la banque en cas de défaut de paiement (différent de l’assurance emprunteur qui vous protège vous).',
                  type: 'plus',
                  value: formatNumber(guarantyFees, { suffix: '€' }),
                },
                {
                  label: 'Frais de courtage Pretto',
                  tooltip: 'Coût de l’accompagnement et du service de Pretto, selon le montant emprunté.',
                  type: 'plus',
                  value: formatNumber(brokerageFees, { suffix: '€' }),
                },
                {
                  label: 'Frais de dossier',
                  tooltip: 'Frais d’étude et d’édition de l’offre par la banque.',
                  type: 'plus',
                  value: formatNumber(applicationFees, { suffix: '€' }),
                },
                contribution && { label: 'Apport', type: 'minus', value: formatNumber(contribution, { suffix: '€' }) },
                bridge && {
                  label: 'Prêt relais',
                  tooltip:
                    'Avance de trésorerie utilisée comme apport, remboursée à la vente de votre bien. Ce montant est calculé à partir de la valeur de votre bien actuel et du prêt qu’il vous reste à rembourser.',
                  type: 'minus',
                  value: formatNumber(bridge.value, { suffix: '€' }),
                },
                { label: 'Montant emprunté', type: 'equal', value: formatNumber(loanAmount, { suffix: '€' }) },
                ptz && {
                  label: 'Dont prêt à taux zéro',
                  picto: ZeroPercent,
                  value: formatNumber(ptz.amount, { suffix: '€' }),
                },
                ecoPtz?.amount && {
                  label: 'Dont éco prêt à taux zéro',
                  picto: GreenZeroPercent,
                  tooltip:
                    'L’éco-PTZ est accessible pour des travaux de rénovation énergétique, le montant sera ajusté en fonction du type de travaux avec un expert Pretto.',
                  value: formatNumber(ecoPtz.amount, { suffix: '€' }),
                },
              ].filter(Boolean),
              title: 'Projet',
            },
            {
              entries: [
                {
                  label: "Durée d'emprunt",
                  picto: Clock,
                  tooltip: 'Durée pendant laquelle vous remboursez votre crédit.',
                  value: formatNumber(loanDuration / 12, { suffix: 'ans' }),
                },
                {
                  label: 'Mensualité',
                  picto: Calendar,
                  tooltip:
                    'Le montant remboursé à la banque tous les mois (intérêts + montant emprunté), assurance de prêt exclue.',
                  value: formatNumber(payment, { suffix: '€ / mois' }),
                },
                {
                  label: 'Taux d’intérêt',
                  picto: Percent,
                  value: `${mainLoansRate.toLocaleString('fr', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })} %`,
                },
                {
                  label: 'Intérêts',
                  picto: Coins,
                  tooltip: 'Coût du crédit (% du montant emprunté) hors assurance.',
                  value: formatNumber(interests, { suffix: '€' }),
                },
                { label: 'Endettement', picto: Burden, value: formatNumber(indebtednessRate * 100, { suffix: '%' }) },
              ],
              title: 'Prêt',
            },
          ],
        ],
        onClick: handleCardCTAClick,
      },
      onEdit: handleOpenDialog,
      onCloseTimeline: handleCloseTimeline,
      onDetailsClose() {
        setIsDetailsOpen(false)
      },
      onOpenTimeline: handleOpenTimeline,
      steps,
    }

    return <ResultsPageContent {...resultsPageContentProps} />
  }

  const simulationContentProps = () => {
    const projectKind = project.project_kind
    const projectProps = editableFields.reduce((previous, { key, value }) => ({ ...previous, [key]: value }), {})

    const steps = [
      { href: `/project/${projectKind}/introduction`, title: 'Votre projet' },
      { href: `/project/${projectKind}/lodging`, title: 'Vos informations' },
      { href: '/simulation', onClick: handleCloseTimeline, title: 'Résultats' },
    ]

    const getBlockInfoProps = () => {
      const {
        purchase: { maturity },
      } = project

      return getBlockInfoByMaturity({
        maturity,
        typology,
        bookingInfos,
        onOpenMutualizedAgenda: handleOpenMutualizedAgenda,
        historyPush,
      })
    }

    return {
      blockRateDoesntCountProps: blockRateDoesntCountProps(),
      date: temporal().format('DD/MM/YYYY'),
      footerInfosProps: { count: trustpilotData.numberOfReviews, rate: trustpilotData.score },
      isLoggedIn,
      isOnboardingBlockVisible: typology === 'client' && !isDashboardPageReached,
      hasAppointment: !!bookingInfos.start,
      isTimelineOpen,
      isTimelineVisible: typology === 'prospect',
      onCloseTimeline: handleCloseTimeline,
      onOpen: handleOpenDialog,
      onOpenTimeline: handleOpenTimeline,
      onSimulateInsurance: handleSimulateInsurance,
      projectKind,
      repurchaseAlert: {
        ...repurchaseAlert,
        insuranceSavings,
        kind: repurchaseAlert.minSavings < 0 ? 'noSavings' : 'goodRates',
      },
      resultCardsProps: {
        blockInfoProps: getBlockInfoProps(),
        cards: resultsCards(),
        isAppointmentAvailable,
        isDashboardPageReached,
        onCardClick: handleCardClick,
        onCardCTAClick: handleCardCTAClick,
        projectProps: { projectKind, ...projectProps },
        typology,
      },
      secondActionProps: secondActionProps(),
      steps,
    }
  }

  const editableRows = () =>
    editableFields
      .filter(({ key }) => !(project.project_kind === 'renegotiation' && key === 'contribution'))
      .map(({ label, value, path }) => ({
        label,
        numberFieldProps: {
          onChange: handleChangeRow(path),
          onKeyPress: handleKeyPressRowChanges,
          value: changes[path] === undefined ? value : changes[path],
        },
        onClick: handleClickRow,
      }))

  const simulationSidebarProps = () => {
    return {
      editableRowsProps: { isChanging: isPriceChanging, onValidate: handleValidateRowChanges, rows: editableRows() },
      onOptimize,
      projectKind: project.project_kind,
      controls: simulationControlsProps(),
    }
  }

  const getSelectedResult = () => {
    return filterResultsByDuration(results, computedDuration)[0] || {}
  }

  const simulationControlsProps = () => {
    const durations = getLoanDurationsFromResults(results)
    const payments = sortBy(durations.map(duration => filterResultsByDuration(results, duration)[0].financing.payment))

    const {
      financing: { payment },
    } = getSelectedResult()

    const sharedSliderProps = { onAfterChange: handleAfterChange, step: null }

    return [
      {
        format: 'duration',
        label: 'Durée d’emprunt',
        props: {
          ...sharedSliderProps,
          marks: durations.map(duration => duration / MONTHS_IN_YEAR),
          max: max(durations) / MONTHS_IN_YEAR,
          min: min(durations) / MONTHS_IN_YEAR,
          onChange: changeHandler('duration'),
          value: computedDuration / MONTHS_IN_YEAR,
        },
        type: 'slider',
      },
      {
        format: 'currency',
        label: 'Mensualités',
        props: {
          ...sharedSliderProps,
          marks: payments,
          max: max(payments),
          min: min(payments),
          onChange: changeHandler('payment'),
          value: payment,
        },
        type: 'slider',
      },
    ]
  }

  const editableFieldsProps = () => {
    return { fields: editableRows() }
  }

  const simulationDetailsProps = () => {
    return { editableFieldsProps: editableFieldsProps(), onOptimize, controls: simulationControlsProps() }
  }

  const handleNextClick = async () => {
    await onUpdateProject()

    if (typology === 'client') {
      historyPush('/')
      return
    }

    historyPush('/simulation/subscribe')
  }

  const handleCardCTAClick = () => {
    handleNextClick('cta_card')
  }

  const handlePreviousClick = () => {
    const url =
      project.project_kind === 'purchase'
        ? isLoggedIn
          ? '/project/purchase/contribution'
          : '/project/purchase/rate-alert/wantsRateAlert'
        : '/project/renegotiation/loan'
    historyPush(url)
  }

  const handleAppointmentClick = () => {
    const path = '/simulation/subscribe?kind=appointment_result_page'
    onProjectValidate(path)
  }

  const footerProps = !isDashboardPageReached
    ? {
        onNextClick: handleNextClick,
        onPreviousClick: typology !== 'client' ? handlePreviousClick : null,
      }
    : null

  const handleSubmitEmail = () => {
    onProjectValidate()
    signUp()
  }

  const {
    component: emailFieldComponent,
    value: emailValue,
    setIsButtonLoading,
    invalidate,
  } = useEmailField({
    inputProps: { placeholder: 'Email', required: true },
    isSingle: true,
    onSubmit: handleSubmitEmail,
    validators: [
      (value, isCheckRequested, inputProps, reason) => ({
        condition: ERROR_TYPES.includes(reason),
        message: getAuthErrorMessage(reason, <Link to="/login">ici</Link>),
        state: 'warning',
      }),
    ],
  })

  const signUp = async () => {
    try {
      setIsButtonLoading(true)
      const error = await onSignUp({ assignment: 'project', email: emailValue, source: 'subscribe' })

      if (error) {
        invalidate(error)
        return
      }
    } catch (error) {
      invalidate('server_error')
    } finally {
      setIsButtonLoading(false)
    }
  }

  const handleGoogleConnect = () => {
    trackAction('GOOGLE_BUTTON_CLICKED', { google_location: 'simulation_result_page' })
    requestGoogleConnect({ projectID })
  }

  const simulationPageProps = () => {
    const cta =
      typology !== 'client' && isAppointmentAvailable ? (
        <ButtonInline isSecondary onClick={handleModify}>
          Modifier plus d’infos
        </ButtonInline>
      ) : null

    return {
      advisor,
      cta,
      contentComponent: contentComponent(),
      emailFieldComponent,
      footerProps,
      isAppointmentAvailable,
      isDialogOpen,
      isLanguageInfoOpen,
      isLoggedIn,
      isSaveOpen,
      isScoreDetailsOpen,
      onCguClick: trackLegalLink('cgu'),
      onClose: handleCloseDialog,
      onCloseLanguageInfo: closeLanguageInfo,
      onCloseSave: handleCloseSave,
      onCloseScoreDetails: handleCloseScoreDetails,
      onGoogleConnect: handleGoogleConnect,
      onPrivacyClick: trackLegalLink('privacy'),
      onValidate: handleValidateRowChanges,
      scoreDetailsProps: scoreDetailsProps(),
      simulationContentProps: simulationContentProps(),
      simulationDetailsProps: simulationDetailsProps(),
      simulationSidebarProps: simulationSidebarProps(),
      typology,
    }
  }

  const trackSimulationBannerAction = action =>
    trackAction('SIMULATION_RESULTS_BANNER_CLICKED', { simulation_results_banner_action: action })

  const handleSimulationBannerCancel = () => {
    onProjectChangeCancel()
    trackSimulationBannerAction('cancel')
  }

  const handleSimulationBannerSave = () => {
    onProjectChangeSave()
    trackSimulationBannerAction('save')
  }

  const simulationBannerProps = () => ({
    actions: [
      { label: 'Enregistrer', onClick: handleSimulationBannerSave },
      { label: 'Annuler', onClick: handleSimulationBannerCancel },
    ],
    isVisible: isProjectChanging,
  })

  const handleOptimize = args => {
    handleCloseDialog()
    onOptimizeChoose(args)
  }

  const headerProps = {
    accountVariant: typology === 'preLead' ? 'onlyInitial' : null,
    isMobileHidden: typology === 'prospect',
  }

  const navigationItemsObject = getNavigationItemsList({
    hasFeatureAccess,
    isDashboardPageReached,
    isPriceSubmitted,
    projectKind: project.project_kind,
    status,
    typology,
  })

  const handleBookingInfos = bookingInfos => {
    setBookingInfos(bookingInfos)
  }

  if (isOptimizing) {
    return (
      <OptimizePage onChoose={handleOptimize} onCancel={onOptimizeCancel} fetchOptimizations={fetchOptimizations} />
    )
  }

  return (
    <>
      <SimulationBanner {...simulationBannerProps()} />
      <Responsive max="laptop">
        <Header {...headerProps} navigationItemsList={navigationItemsObject.tablet} />
      </Responsive>
      <Responsive min="laptop">
        <Header {...headerProps} navigationItemsList={navigationItemsObject.desktop} />
      </Responsive>
      <SimulationPage {...simulationPageProps()} />

      <MutualizedAgenda
        isDriAllowed={scoreZeroMinute?.callBackNowAllowed}
        isOpen={isMutualizedAgendaOpen}
        occurrence={Occurrence.PUSHY}
        onBookingInfos={handleBookingInfos}
        onClose={handleCloseMutualizedAgenda}
        onContinue={handleCloseMutualizedAgenda}
      />

      <AdrenaleadIframe type="newLead" isClient={typology === 'client'} />
    </>
  )
}

ResultsPage.propTypes = {
  brokerageFees: PropTypes.number.isRequired,
  chosenOptimization: PropTypes.string,
  dealValue: PropTypes.number,
  defaultDuration: PropTypes.number,
  duration: PropTypes.number.isRequired,
  editableFields: PropTypes.array.isRequired,
  fetchOptimizations: PropTypes.func.isRequired,

  isOptimizing: PropTypes.bool.isRequired,
  isProjectChanging: PropTypes.bool.isRequired,
  isMutating: PropTypes.bool.isRequired,
  onDurationChange: PropTypes.func.isRequired,

  onOptimize: PropTypes.func.isRequired,
  onOptimizeCancel: PropTypes.func.isRequired,
  onOptimizeChoose: PropTypes.func.isRequired,

  onProjectChange: PropTypes.func.isRequired,
  onProjectChangeCancel: PropTypes.func.isRequired,
  onProjectChangeSave: PropTypes.func.isRequired,
  onProjectEdit: PropTypes.func.isRequired,
  onProjectValidate: PropTypes.func.isRequired,
  onUpdateProject: PropTypes.func.isRequired,

  onSignUp: PropTypes.func.isRequired,

  onValidateDefaultDuration: PropTypes.func.isRequired,

  project: PropTypes.shape({
    contribution: PropTypes.number,
    facts: PropTypes.shape({
      delegation_insurance_rate: PropTypes.number,
      insurance_rate: PropTypes.number,
      notary_fees: PropTypes.number,
    }).isRequired,
    project_kind: PropTypes.oneOf(['capacity', 'purchase', 'renegotiation']).isRequired,
    purchase: PropTypes.shape({
      furniture_price: PropTypes.number,
      property_price: PropTypes.number,
      works_price: PropTypes.number,
      maturity: PropTypes.string,
    }),
    renegotiation: PropTypes.shape({
      facts: PropTypes.shape({ remaining_duration: PropTypes.number, remaining_interests: PropTypes.number }),
      remaining_principal: PropTypes.number,
    }),
    solicited_banks: PropTypes.arrayOf(PropTypes.shape({ rate: PropTypes.number })).isRequired,
  }).isRequired,
  results: PropTypes.array.isRequired,
  simulationMedium: PropTypes.shape({ current: PropTypes.string }).isRequired,
  shouldRefetchBooking: PropTypes.bool,
}

const Results = props => <SubscribeConsumer>{context => <ResultsPage {...props} {...context} />}</SubscribeConsumer>

export default Results
