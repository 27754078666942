import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'

import { Timeline } from '@pretto/app/src/SharedComponents/Timeline/Timeline'

import styled from 'styled-components'

export const Template = styled.div`
  ${grid(4, { isLeftAligned: true, isRightAligned: true })};
  height: 100%;
  grid-template-rows: auto 1fr;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${grid(12, { isLeftAligned: true, isRightAligned: true })};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${grid(10, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const StyledTimeline = styled(Timeline)`
  ${column(4)};
  grid-row: 1;
  background-color: ${({ theme }) => theme.colors.neutral5};
  padding: ${g(2)} ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
    padding: ${g(4)} ${g(11, 2)} ${g(3)} ${g(11, 2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column(12)};
    padding: ${g(4)} ${g(13)} ${g(3)} ${g(13)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column(10)};
  }
`

export const SideIllustration = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(5)};
    display: block;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: #0c3b37;
    grid-row: 2;
    height: fit-content;
    background-image: url(${getCloudinaryUrl(`v1670509270/pretto-ui/UploadDocumentDesktop.gif`)});
    background-position: right center;
    margin-top: unset;
    height: 100%;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column(4)};
  }
`

export const MainContent = styled.div<{ $isIllustrationDisplayed?: boolean }>`
  ${column(4)};
  margin-top: ${g(4)};
  grid-row: 2;
  height: auto;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
    margin-top: ${g(5)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([6, 7])}
    margin-top: ${g(8)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column([5, 6])}
  }
`

export const Grid = styled.div`
  ${grid(4, { isLeftAligned: false, isRightAligned: false })};
  grid-template-rows: 1fr auto;
  height: 100%;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${grid(8, { isLeftAligned: true, isRightAligned: true })};
    grid-template-rows: auto auto 1fr;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${grid(7, { isLeftAligned: true, isRightAligned: true })};
    grid-template-rows: 1fr auto;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${grid(6, { isLeftAligned: true, isRightAligned: true })};
  }
`

export const Content = styled.div`
  ${column([2, 4])};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 6])};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 5])};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column([2, 4])};
  }
`

export const TabletIllustration = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 6])};
    display: block;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: #0c3b37;
    height: ${g(26, 4)};
    background-image: url(${getCloudinaryUrl(`v1670509270/pretto-ui/UploadDocumentTablet.gif`)});
    border-radius: ${g(1)};
    margin-top: ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`

export const ButtonContainer = styled.div`
  ${column([2, 4])};
  display: flex;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${column(8)};
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    ${column(7)};
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    ${column(6)};
  }
`

export const NextButton = styled(PrimaryButtonFix)`
  width: 100%;
  text-align: center;
  margin: auto ${g(2)} ${g(2)} ${g(2)};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: unset;
    margin: auto ${g(3)} ${g(3)} auto;
  }
`
