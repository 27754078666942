import { extractBanksInfo } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/prefillContext/extractBanksInfo'
import { extractBrokerInfo } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/prefillContext/extractBrokerInfo'
import { extractComortgagorInfo } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/prefillContext/extractComortgagorInfo'
import type { ApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'

export const prefillContext = (data: ApplicationIntroductionQuery) => {
  const { accounts, project } = data
  const brokerInfo = extractBrokerInfo(project?.solicited_brokers)
  const bankInfo = extractBanksInfo(project?.solicited_banks)
  const comortgagorInfo = extractComortgagorInfo(accounts, project?.profile)

  return {
    userFirstName: accounts[0]?.firstName,
    maturity: project?.purchase?.maturity,
    ...brokerInfo,
    ...bankInfo,
    ...comortgagorInfo,
    hasNotaryContact: project?.purchase?.notary?.name ? true : null,
    notaryContact: project?.purchase?.notary?.name
      ? {
          lastname: project.purchase.notary.name,
          email: project.purchase.notary.email,
          phone: project.purchase.notary.phone,
        }
      : null,
    hasOverdraft: project?.profile?.mortgagors?.[0]?.banking_incident ?? null,
    comortgagorHasOverdraft:
      typeof project?.profile?.mortgagors?.[1]?.banking_incident === 'boolean'
        ? project.profile.mortgagors[1].banking_incident
        : null,
    hasJointAccount: project?.profile?.joint_banks && project.profile.joint_banks.length > 0 ? true : null,
    bankAccounts: project?.profile?.mortgagors?.[0]?.current_banks || [],
    comortgagorBankAccounts: project?.profile?.mortgagors?.[1]?.current_banks || [],
    jointAccounts: project?.profile?.joint_banks || [],
  }
}
