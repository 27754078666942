import { InputState } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/types/inputStates.types'

import { forwardRef } from 'react'
import { ThemeProvider } from 'styled-components'

import * as S from './InputSearch.styles'

export type InputProps = React.ComponentPropsWithRef<'input'> & {
  clearValue: () => void
  isOpen: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  state: InputState
  value?: string
}

export const InputSearch: React.FC<InputProps> = forwardRef(
  ({ clearValue, isOpen, onChange, placeholder = '-', state, value, ...props }, ref) => {
    const handlePictoClick = () => {
      if (value && state === 'default') {
        clearValue()
      }
    }

    const handleOnChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event)
    }

    return (
      <ThemeProvider theme={{ isOpen, state, value }}>
        <S.InputSearch tabIndex={-1} {...props}>
          <S.HiddenPlaceholder>{value || placeholder}</S.HiddenPlaceholder>
          <S.Input ref={ref} onChange={handleOnChangeValue} placeholder={placeholder} value={value} />
          <S.Picto type={state === 'default' ? (value ? 'clear' : 'search') : state} onClick={handlePictoClick} />
        </S.InputSearch>
      </ThemeProvider>
    )
  }
)

InputSearch.displayName = 'InputSearch'
