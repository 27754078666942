import React, { forwardRef } from 'react'

import * as S from './Option.styles'

export type OptionProps = React.ComponentPropsWithRef<'li'> & {
  isSelected?: boolean
  label: string
  value: string
}

export const Option: React.FC<OptionProps> = forwardRef(({ isSelected = false, label, value, ...props }, ref) => (
  <S.Option
    $isSelected={isSelected}
    aria-selected={isSelected}
    data-value={value}
    ref={ref}
    role="option"
    tabIndex={0}
    {...props}
  >
    <S.Label> {label}</S.Label>
    {isSelected && <S.SelectedPicto />}
  </S.Option>
))

Option.displayName = 'Option'
