import { DataContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import type { NavigationProps } from '@pretto/app/src/Capacity/types/navigation'
import { BlocSimulationProps } from '@pretto/app/src/Capacity/views/ResultPage/components/BlocSimulation/BlocSimulation'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { useContext } from 'react'

import { getCalcTableProps } from './getCalcTableProps'

export const useBlocSimulationProps = ({ onContinue, ...props }: NavigationProps): BlocSimulationProps => {
  const { typology, isDashboardPageReached = false } = useUser()

  const {
    contribution,
    data,
    handleChangePayment,
    handleChangeContribution,
    handleChangeDuration,
    loadingResults,
    maxPayment,
    payment,
    refreshCapacity,
    selectedLoanDuration,
  } = useContext(DataContext)

  const trackAction = useTracking()

  const duration = ensureNotNil(selectedLoanDuration)
  const result = data.capacity.find(({ financing }) => duration === financing.loanDuration)

  return {
    ...props,
    get calcTableProps() {
      if (!result) {
        return null
      }

      return { rows: getCalcTableProps(result) }
    },
    isLast: typology === 'client' || !result,
    isLoading: loadingResults,
    isDashboardPageReached,
    onContinue(event) {
      onContinue?.(event, 'simulation')
    },
    onResultExpanded() {
      trackAction('CAPACITY_RESULT_DETAILS_VIEWED')
    },
    get price() {
      if (!result) {
        return null
      }

      return result.project.purchase.property_price
    },
    get simulatorProps() {
      const durationValues = data.capacity.map(({ financing }) => financing.loanDuration)

      return {
        contributionValue: contribution,
        durationValue: duration,
        durationValues,
        onChangeContribution(value: number) {
          handleChangeContribution(value)
          refreshCapacity('contribution', { contribution: value })
        },
        onChangeDuration(value: number) {
          handleChangeDuration(value)
        },
        onChangePayment(value: number) {
          handleChangePayment(value)
          refreshCapacity('contribution', { payment: value })
        },
        paymentValue: payment,
        paymentValueMax: maxPayment,
        paymentValueMin: 200,
      }
    },
  }
}
