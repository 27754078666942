import SimulationContent from '@pretto/bricks/app/simulator/components/SimulationContent'
import { useBreakpoint } from '@pretto/bricks/assets/utility'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column } from '@pretto/bricks/components/layout/Grid'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { ResultCard } from '@pretto/app/src/Capacity/Containers/CapacityPage/containers/Content/lib/ResultCard'
import { ControlsContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/ControlsContext'
import { DataContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/DataContext'
import { SaveContext } from '@pretto/app/src/Capacity/Containers/CapacityPage/contexts/SaveContext'
import Front from '@pretto/app/src/Helpers/frontchat'
import {
  BookingInfos,
  getBlockInfoByMaturity,
} from '@pretto/app/src/Simulation/Containers/ResultsPage/lib/getBlockInfoByMaturity'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { useTracking } from '@pretto/app/src/lib/tracking'
import { useTrustpilotData } from '@pretto/app/src/lib/useTrustpilotData'
import { Bookmark, Phone } from '@pretto/picto'

import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const PROJECT_KIND = 'capacity'

interface ContentProps {
  bookingInfos: BookingInfos
  onNextClick: (param: string) => unknown
  project: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
  onOpenMutualizedAgenda: () => void
}

export const Content: React.FC<ContentProps> = ({ bookingInfos, project, onNextClick, onOpenMutualizedAgenda }) => {
  const history = useHistory()
  const trackAction = useTracking()
  const { isDashboardPageReached, typology } = useUser()
  const { isLoggedIn } = useAuth()
  const { isMobile } = useBreakpoint()

  const { onOpenSave } = useContext(SaveContext)
  const { onOpenDialog, onCloseDialog } = useContext(ControlsContext)
  const { data, selectedLoanDuration, contribution, loadingResults } = useContext(DataContext)

  const [isTimelineOpen, setIsTimelineOpen] = useState(false)

  const trustpilotData = useTrustpilotData()

  useEffect(() => {
    Front(isMobile && !isTimelineOpen ? 'hide' : 'show')
  }, [isMobile, isTimelineOpen])

  const handleAppointmentClick = () => {
    history.push('/simulation/subscribe?kind=appointment_result_page')
  }
  const secondActionProps = () => {
    switch (typology) {
      case 'prospect':
        return {
          onClick: onOpenSave,
          picto: Bookmark,
          text: 'Sauvegarder',
        }
      case 'preLead':
        return {
          onClick: handleAppointmentClick,
          picto: Phone,
          text: 'Prendre RDV',
        }
      default:
        return null
    }
  }

  const handleCloseTimeline = () => {
    setIsTimelineOpen(false)
  }

  const handleOpenTimeline = () => {
    setIsTimelineOpen(true)
  }

  const handleCardClick = () => {
    trackAction('CAPACITY_RESULT_DETAILS_VIEWED')
  }

  const handleCardCTAClick = () => {
    onNextClick('cta_card')
  }

  const getBlockInfoProps = () => {
    const {
      data: {
        project: {
          purchase: { maturity },
        },
      },
    } = project

    return getBlockInfoByMaturity({
      maturity,
      typology,
      bookingInfos,
      historyPush: history.push,
      onOpenMutualizedAgenda,
    })
  }

  const resultCardsProps = {
    blockInfoProps: getBlockInfoProps(),
    cards: ResultCard({ data, selectedLoanDuration, contribution }),
    isDashboardPageReached,
    onCardClick: handleCardClick,
    onCardCTAClick: handleCardCTAClick,
    projectProps: { projectKind: PROJECT_KIND },
    typology,
  }

  const steps = [
    { href: '/project/capacity/introduction', title: 'Votre projet' },
    { href: '/project/capacity/lodging', title: 'Vos informations' },
    {
      href: '/capacity',
      onClick: handleCloseTimeline,
      title: 'Résultats',
    },
  ]

  return (
    <SimulationContentComponent
      isLoading={loadingResults}
      onClose={onCloseDialog}
      onOpen={onOpenDialog}
      secondActionProps={secondActionProps()}
      onOpenSave={onOpenSave}
      isLoggedIn={isLoggedIn}
      footerInfosProps={{ count: trustpilotData.numberOfReviews, rate: trustpilotData.score }}
      isOnboardingBlockVisible={typology === 'client' && !isDashboardPageReached}
      isTimelineOpen={isTimelineOpen}
      isTimelineVisible={typology === 'prospect'}
      projectKind={PROJECT_KIND}
      resultCardsProps={resultCardsProps}
      onCloseTimeline={handleCloseTimeline}
      onOpenTimeline={handleOpenTimeline}
      steps={steps}
    />
  )
}

const SimulationContentComponent = styled(SimulationContent)`
  ${column([2, 4])};
  padding: ${g(2)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${g(7)} 0;
  }
`
