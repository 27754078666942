import { type OptionProps } from '@pretto/zen/reveal/atoms/textfields/Option/Option'

import { forwardRef, useEffect, useRef, useState } from 'react'

import * as S from './OptionsList.styles'

const DEFAULT_NO_RESULTS_MESSAGE = 'Aucun résultat trouvé'

export type OptionsListProps = Omit<React.ComponentPropsWithRef<'ul'>, 'onClick'> & {
  isFirstOptionFocused: boolean
  onClick: (value: string) => void
  optionsList: OptionProps[]
  selectedOptionValue?: string
  noResultsMessage?: string
}

type ChangeFocusProps = {
  event: React.KeyboardEvent<HTMLElement>
  index: number
}

export const OptionsList: React.FC<OptionsListProps> = forwardRef(
  (
    {
      isFirstOptionFocused,
      noResultsMessage = DEFAULT_NO_RESULTS_MESSAGE,
      onClick,
      optionsList,
      selectedOptionValue,
      ...props
    },
    ref
  ) => {
    const [focusIndex, setFocusIndex] = useState<number | null>(null)

    const optionsRefs = useRef<HTMLLIElement[] | []>([])

    useEffect(() => {
      if (isFirstOptionFocused && optionsRefs.current.length > 0) {
        setFocusIndex(0)
      }
    }, [isFirstOptionFocused])

    useEffect(() => {
      if (focusIndex === null) return
      if (focusIndex === null && isFirstOptionFocused && optionsRefs.current.length > 0) {
        setFocusIndex(0)
      }
      if (focusIndex >= 0 && optionsRefs.current.length > 0) {
        optionsRefs.current[focusIndex].focus()
      }
    }, [focusIndex])

    const indexMin = 0
    const indexMax = optionsList.length - 1

    const nextTab = () => {
      const nextIndex = focusIndex ? focusIndex + 1 : 1
      if (nextIndex > indexMax) {
        setFocusIndex(indexMin)
        return
      }
      setFocusIndex(nextIndex)
    }

    const previousTab = () => {
      const previousIndex = focusIndex ? focusIndex - 1 : -1
      if (previousIndex < indexMin) {
        setFocusIndex(indexMax)
        return
      }
      setFocusIndex(previousIndex)
    }

    const changeFocus = ({ event, index }: ChangeFocusProps) => {
      event.preventDefault()
      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            previousTab()
            break
          }
          nextTab()
          break

        case 'ArrowDown':
          nextTab()
          break

        case 'ArrowUp':
          previousTab()
          break

        case 'Escape':
          if (focusIndex) {
            optionsRefs.current[focusIndex].blur()
          }
          setFocusIndex(null)
          break

        case 'Enter':
        case ' ':
          onClick(optionsList[index].value)
          setFocusIndex(null)
          break
        default:
      }
    }

    return (
      <S.OptionsList $numberOfOptions={optionsList.length} ref={ref} {...props}>
        {optionsList.length === 0 ? (
          <S.NoResults>{noResultsMessage}</S.NoResults>
        ) : (
          optionsList.map((option, index) => {
            const handleOptionRef = (node: HTMLLIElement) => {
              optionsRefs.current[index] = node
            }

            const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
              changeFocus({ event, index })
            }

            const handleOnClick = () => {
              onClick(option.value)
            }

            return (
              <S.Option
                isSelected={option.value === selectedOptionValue}
                key={option.value}
                onClick={handleOnClick}
                onKeyDown={handleKeyDown}
                ref={handleOptionRef}
                {...option}
              />
            )
          })
        )}
      </S.OptionsList>
    )
  }
)

OptionsList.displayName = 'OptionsList'
