export const INITIAL_CONTEXT = {
  applicationIntroductionShown: () => Promise.resolve(),
  bankAccounts: [],
  banksToAvoid: [],
  brokerProposal: null,
  comortgagorBankAccounts: [],
  comortgagorContact: null,
  comortgagorHasOverdraft: null,
  hasABroker: null,
  hasBanksToAvoid: null,
  hasBrokerProposal: null,
  hasComortgagor: null,
  hasJointAccount: null,
  hasNotaryContact: null,
  hasOverdraft: null,
  isComortgagorInvited: false,
  isQueryLoading: true,
  jointAccounts: [],
  maturity: null,
  notaryContact: null,
  persistComortgagor: () => Promise.resolve({ state: 'success' }),
  persistIntroduction: () => Promise.resolve(),
  signedWithBroker: null,
  userFirstName: null,
}
