import { validatePhone } from '@pretto/bricks/core/utility/form/validatePhone'

import type { FieldState } from '@pretto/zen/reveal/types/Field'

import { validateEmail } from '@pretto/app/src/Sentences/lib/email'

type ValidateContactProps = {
  email?: string | null
  phone?: string | null
  emailsAssigned?: string[]
}

export const validateContact = ({ email, phone, emailsAssigned }: ValidateContactProps) => {
  const emailProps = () => {
    if (email && !validateEmail(email)) {
      return {
        state: 'error' as FieldState,
        message: 'Veuillez saisir une adresse email valide',
      }
    }

    if (email && emailsAssigned?.includes(email)) {
      return {
        state: 'error' as FieldState,
        message: 'Cette adresse email est déjà utilisée',
      }
    }

    return null
  }

  const phoneProps = () => {
    if (phone && !validatePhone(phone)) {
      return {
        state: 'error' as FieldState,
        message: 'Veuillez saisir un numéro de téléphone valide',
      }
    }

    return null
  }

  return {
    emailProps: emailProps(),
    phoneProps: phoneProps(),
    isEmailValid: !emailProps(),
    isPhoneValid: !phoneProps(),
  }
}
