import React, { ReactNode } from 'react'

import * as S from './ModalLikePage.styles'

export type ModalLikePageProps = {
  children: ReactNode
}

export const ModalLikePage: React.FC<ModalLikePageProps> = ({ children, ...props }) => (
  <S.StyledModalLikePage {...props}>
    <S.Overlay>
      <S.StyledModalLikeCard>{children}</S.StyledModalLikeCard>
    </S.Overlay>
  </S.StyledModalLikePage>
)
