import { g } from '@pretto/bricks/components/layout'

import { ReactNode } from 'react'
import styled from 'styled-components'

type SentencesProps = {
  children: ReactNode
}

export const Sentences = ({ children, ...props }: SentencesProps) => (
  <StyledSentences {...props}>{children}</StyledSentences>
)

const StyledSentences = styled.div`
  line-height: ${g(5)};
`
