import { g } from '@pretto/bricks/components/layout'

import { TextFieldSearch } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/TextFieldSearch'

import { ChipList } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/ChipList/ChipList'

import { useEffect, useState } from 'react'
import styled from 'styled-components'

type Option = {
  value: string
  label: string
}

export type InputSearchAndChipsProps = {
  optionsList: Option[]
  preSelectedOptions?: string[]
  getSelectedOption: (value: string[]) => void
  placeholder: string
}

const normalizeString = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const InputSearchAndChips = ({
  optionsList,
  preSelectedOptions = [],
  getSelectedOption,
  placeholder,
  ...props
}: InputSearchAndChipsProps) => {
  const [optionsSelected, setOptionsSelected] = useState<string[]>(preSelectedOptions)
  const [optionsListToDisplay, setOptionsListToDisplay] = useState(optionsList)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    getSelectedOption(optionsSelected)
  }, [optionsSelected])

  const handleDeleteBank = (value: string) => {
    setOptionsSelected(currentValue => currentValue.filter(option => option !== value))
  }

  const handleOnChange = (value: string) => {
    setOptionsSelected(currentValue => [...currentValue, value])
    setSearchValue('')
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    const normalizedSearch = normalizeString(value)
    const listToDisplay = optionsList.filter(option => normalizeString(option.label).includes(normalizedSearch))
    const withoutSelected =
      optionsSelected.length > 0 ? listToDisplay.filter(bank => !optionsSelected.includes(bank.value)) : listToDisplay
    setOptionsListToDisplay(withoutSelected)
  }

  return (
    <StyledInputSearchAndChips {...props}>
      <TextFieldSearch
        onChange={handleOnChange}
        onSearchChange={handleSearchChange}
        optionsList={optionsListToDisplay}
        placeholder={placeholder}
        searchValue={searchValue}
        value={searchValue}
      />

      <StyledChipList
        chipList={optionsList.filter(option => optionsSelected.includes(option.value))}
        onDelete={handleDeleteBank}
      />
    </StyledInputSearchAndChips>
  )
}

const StyledInputSearchAndChips = styled.div``

const StyledChipList = styled(ChipList)`
  margin-top: ${({ chipList }) => (chipList.length > 0 ? g(2) : '0px')};
`
