import { Maturity } from '@pretto/app/src/types/gateway/enums'

import type { ContextValidator } from '../../types/applicationGraphValidators.type'

export const isHot: ContextValidator = context =>
  ![Maturity.UnderStudy, Maturity.SearchStarted].includes(context.maturity as Maturity)

export const isNotHot: ContextValidator = context =>
  [Maturity.UnderStudy, Maturity.SearchStarted].includes(context.maturity as Maturity)

export const hasSaleAgreementSigned: ContextValidator = context => context.maturity === Maturity.SaleAgreementSigned

export const hasNoSaleAgreementSigned: ContextValidator = context => context.maturity !== Maturity.SaleAgreementSigned

export const hasNoBroker: ContextValidator = context => !context.hasABroker

export const hasBrokerProposal: ContextValidator = context => !!context.hasBrokerProposal

export const hasNoBrokerProposal: ContextValidator = context => !context.hasBrokerProposal

export const hasProposalFilled: ContextValidator = context => !!context.brokerProposal

export const hasBanksToAvoid: ContextValidator = context => !!context.hasBanksToAvoid

export const hasComortgagor: ContextValidator = context => !!context.hasComortgagor

export const hasNoComortgagor: ContextValidator = context => !context.hasComortgagor

export const hasNotaryContact: ContextValidator = context => !!context.hasNotaryContact

export const hasJointAccount: ContextValidator = context => !!context.hasJointAccount

export const hasNoBanksToAvoid: ContextValidator = context => !context.hasBanksToAvoid

export const hasNoNotaryContact: ContextValidator = context => !context.hasNotaryContact
