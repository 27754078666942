import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import { getCloudinaryUrl } from '@pretto/bricks/core/utility/image/getCloudinaryUrl'

import styled from 'styled-components'

const BACKGROUND_IMG_WIDTH = 1224
const BACKGROUND_IMG_HEIGHT = 319

export const StyledModalLikePage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${getCloudinaryUrl(
    'v1654775307/zen/static-assets/illustrations/scenery/city-wall-lineal-neutral3.svg',
    {
      width: BACKGROUND_IMG_WIDTH,
      height: BACKGROUND_IMG_HEIGHT,
    }
  )});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Overlay = styled.div`
  ${grid()};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: auto;
  background-color: rgba(5, 5, 5, 0.8);
`

export const StyledModalLikeCard = styled.div`
  ${column([1, 6])};
  height: 100dvh;
  background-color: ${({ theme }) => theme.colors.white};
  flex-shrink: 0;
  overflow: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    height: auto;
    margin: ${g(3)} 0px;
    border-radius: ${g(1)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
    margin: ${g(9)} 0px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`
