import * as S from './ProgressHeader.styles'

export type ProgressHeaderProps = {
  onClose: () => void
  previousStep: () => void
  progress: number
}

export const ProgressHeader = ({ onClose, previousStep, progress, ...props }: ProgressHeaderProps) => (
  <S.StyledProgressHeader {...props}>
    <S.GoBackButton onClick={previousStep}>
      <S.StyledArrowLeft />
      <S.ButtonText>Retour</S.ButtonText>
    </S.GoBackButton>

    <S.StyledProgressBar progress={progress} />

    <S.CloseButton onClick={onClose}>
      <S.PictoContainer>
        <S.StyledTimesBold />
      </S.PictoContainer>

      <S.ButtonText>Fermer</S.ButtonText>
    </S.CloseButton>
  </S.StyledProgressHeader>
)
