import { g } from '@pretto/bricks/components/layout'

import { TextFieldSearch } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/TextFieldSearch.styles'
import { InputPicto as InputPictoComponent } from '@pretto/zen/reveal/atoms/textfields/TextFieldSearch/components/InputPicto/InputPicto'
import { transition } from '@pretto/zen/reveal/lib/transitionCss'

import styled, { css } from 'styled-components'

const BORDER_COLOR_STATUS = {
  default: 'neutral5',
  error: 'error1',
  warning: 'warning1',
}

export const InputSearch = styled.div`
  ${transition({ propertyName: 'border-color' })};
  ${({ theme }) => theme.typos.body4};
  display: flex;
  justify-content: start;
  align-items: center;
  padding: ${g(1)} ${g(2)};
  height: ${g(6)};
  border-radius: ${g(1)};
  background-color: ${({ theme }) => theme.colors.neutral5};
  color: ${({ theme }) => theme.colors.neutral2};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: ${g(1)};
  text-align: start;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors[BORDER_COLOR_STATUS.default]};

  ${({ theme }) =>
    theme.value &&
    css`
      border-color: ${({ theme }) => theme.colors.neutral3};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => (theme.value ? theme.colors.neutral1 : theme.colors.neutral2)};
    `}

  ${({ theme }) => {
    if (theme.state !== 'default') {
      return css`
        border-color: ${theme.colors[BORDER_COLOR_STATUS[theme.state as keyof typeof BORDER_COLOR_STATUS]]};
      `
    }

    return css`
      ${TextFieldSearch}:hover &&,
      ${TextFieldSearch}:focus-within && {
        border-color: ${({ theme }) => theme.colors.neutral2};
      }
    `
  }}
`
export const HiddenPlaceholder = styled.pre`
  visibility: hidden;
  display: inline;
  ${({ theme }) => theme.typos.body4};
  height: 100%;
  width: 100%;
  padding-right: ${g(3)};
  border: 0;
`

export const Input = styled.input.attrs({ type: 'text' })`
  ${({ theme }) => theme.typos.body4};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: ${g(1, 4)} ${g(5)} ${g(1, 4)} ${g(2)};
  border: 0;
  background: transparent;

  &:focus {
    outline: transparent;
  }

  &::placeholder {
    ${({ theme }) => theme.typos.body4};
    color: ${({ theme }) => theme.colors.neutral2};
  }
`

export const Picto = styled(InputPictoComponent)`
  position: absolute;
  right: ${g(2)};
  top: 50%;
  transform: translateY(-50%);
`
