import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import { useAuth } from '@pretto/app/src/Auth/Containers/AuthProvider'
import { ResultPageProps } from '@pretto/app/src/Capacity/types/resultPage'
import { ResultPage as ResultPageView } from '@pretto/app/src/Capacity/views/ResultPage/ResultPage'
import Front from '@pretto/app/src/Helpers/frontchat'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { ensureNotNil } from '@pretto/app/src/dashboard/lib/ensureNotNil'

import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { DataContext } from './CapacityPage/contexts/DataContext'
import { BlocPretto } from './blocPretto'
import { BlocSimulation } from './blocSimulation'
import { BlocSuggestionsLoggedIn } from './blocSuggestionsLoggedIn'
import { BlocSuggestionsLoggedOut } from './blocSuggestionsLoggedOut'

export const ResultPage: React.FC<ResultPageProps> = props => {
  const { isLoggedIn } = useAuth()

  const shouldHideFront = useBreakpointToValue(true, { tablet: false })

  const { data, selectedLoanDuration } = useContext(DataContext)

  const history = useHistory()

  const { isDashboardPageReached = false, typology } = useUser()

  useEffect(() => {
    if (shouldHideFront) {
      Front('hide')
    } else {
      Front('show')
    }
  }, [shouldHideFront])

  const handleBack = () => {
    if (isLoggedIn) {
      history.push('/')
    } else {
      history.goBack()
    }
  }

  const handleContinue = (event: React.SyntheticEvent<HTMLAnchorElement>) => {
    props.onContinue?.(event, 'simulation')
  }

  const handleEdit = () => {
    history.push('/project/capacity/introduction')
  }

  const duration = ensureNotNil(selectedLoanDuration)
  const result = data.capacity.some(({ financing }) => duration === financing.loanDuration)

  return (
    <ResultPageView
      {...props}
      isLoggedIn={isLoggedIn}
      isNavbarActive={typology !== 'client'}
      onBack={handleBack}
      onContinue={handleContinue}
      onEdit={handleEdit}
    >
      <BlocSimulation {...props} isNotClient={typology !== 'client'} />
      {result && isDashboardPageReached && <BlocSuggestionsLoggedIn {...props} />}
      {result && !isDashboardPageReached && <BlocSuggestionsLoggedOut {...props} />}
      {result && <BlocPretto {...props} />}
    </ResultPageView>
  )
}
