import { g } from '@pretto/bricks/components/layout'

import { Chip } from '@pretto/zen/reveal/atoms/selectors/chip/Chip'

import styled from 'styled-components'

export type ChipListProps = {
  chipList: { label: string; value: string }[]
  onDelete: (value: string) => void
}

export const ChipList = ({ chipList, onDelete, ...props }: ChipListProps) => (
  <StyledChipList {...props}>
    {chipList.map(({ value, label }) => (
      <Chip key={value} label={label} value={value} onClose={onDelete.bind(null, value)} isColored />
    ))}
  </StyledChipList>
)

const StyledChipList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: ${g(1)};
`
