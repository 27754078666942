import { invalidateCache } from '@pretto/app-core/lib/invalidateCache'

import { INITIAL_CONTEXT } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/initialContext'
import { formatContextToGateway } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/formatContextToGateway'
import { prefillContext } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/prefillContext/prefillContext'
import { valuesToUpdate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/valuesToUpdate'
import { useApplicationIntroductionQuery } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/queries/applicationIntroduction.gateway.graphql'
import type { IntroductionContextType } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/introductionContext'
import { useUser } from '@pretto/app/src/User/Containers/UserProvider'
import { INVITE_COMORTGAGOR, UPDATE_ACCOUNT, UPDATE_PROJECT } from '@pretto/app/src/apollo'
import { useStateOperations } from '@pretto/app/src/lib/state/useStateOperations'
import { ProjectKickoffStepKind } from '@pretto/app/src/types/api/enums'

import { useApolloClient, useMutation } from '@apollo/client'
import { createContext, PropsWithChildren, useContext, useEffect } from 'react'

type SetContextProps = React.Dispatch<React.SetStateAction<Partial<IntroductionContextType>>>

export interface IntroductionContextInterface extends IntroductionContextType {
  setContext: SetContextProps
  waitForOngoingOperations: () => Promise<IntroductionContextType>
}

export const IntroductionContext = createContext<IntroductionContextInterface>({} as IntroductionContextInterface)

export const IntroductionContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { projectKickoff } = useUser()
  const { data, loading, error } = useApplicationIntroductionQuery()

  const client = useApolloClient()

  const [inviteComortgagor] = useMutation(INVITE_COMORTGAGOR)
  const [updateAccount] = useMutation(UPDATE_ACCOUNT)
  const [updateProject] = useMutation(UPDATE_PROJECT)

  const [context, setContext, waitForOngoingOperations] = useStateOperations<IntroductionContextType>(INITIAL_CONTEXT)

  useEffect(() => {
    if (!loading && data) {
      setPartialContext(prefillContext(data))
    }
  }, [loading])

  if (error) {
    throw new Error(`Error in introduction context ! ${error.message}`)
  }

  const applicationIntroductionShown = async () => {
    if (projectKickoff?.isNextStep(ProjectKickoffStepKind.ShowDashboardIntro)) {
      await projectKickoff?.completeProjectKickoffStep(ProjectKickoffStepKind.ShowDashboardIntro)
    }
  }

  const persistComortgagor = async () => {
    const { comortgagorContact } = context
    const comortgagorAccountId = data?.accounts[1]?.id

    if (comortgagorAccountId) {
      await updateAccount({
        refetchQueries: ['ApplicationIntroduction'],
        variables: {
          id: comortgagorAccountId,
          payload: {
            first_name: comortgagorContact?.firstname,
            last_name: comortgagorContact?.lastname,
            phone: comortgagorContact?.phone,
          },
        },
      })
    } else {
      const response = (await inviteComortgagor({
        refetchQueries: ['ApplicationIntroduction'],
        variables: {
          firstName: comortgagorContact?.firstname,
          lastName: comortgagorContact?.lastname,
          phone: comortgagorContact?.phone,
          email: comortgagorContact?.email,
        },
      })) as { data: { invite_mortgagor: { error: string } } }

      if (response?.data?.invite_mortgagor?.error === 'account_already_exists') {
        return { state: 'error' }
      }
    }

    await invalidateCache(client)

    return { state: 'success' }
  }

  const persistIntroduction = async () => {
    const formatedFormData = formatContextToGateway(context)

    const valuesToChange = valuesToUpdate({ previousProject: data?.project, formatedFormData })

    await updateProject({
      refetchQueries: ['ApplicationIntroduction'],
      variables: { project: JSON.stringify(valuesToChange) },
    })

    await projectKickoff?.completeProjectKickoffStep(ProjectKickoffStepKind.CompleteFolderIntro)

    await invalidateCache(client)
  }

  const setPartialContext: React.Dispatch<React.SetStateAction<Partial<IntroductionContextType>>> = state => {
    setContext(previousContext => ({
      ...previousContext,
      ...(typeof state === 'function' ? state(previousContext) : state),
    }))
  }

  return (
    <IntroductionContext.Provider
      value={{
        ...context,
        applicationIntroductionShown,
        isQueryLoading: loading,
        persistComortgagor,
        persistIntroduction,
        setContext: setPartialContext,
        waitForOngoingOperations,
      }}
    >
      {children}
    </IntroductionContext.Provider>
  )
}

export const useIntroduction = () => useContext(IntroductionContext)
