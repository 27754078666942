import { APPLICATION_INTRODUCTION_URL as INTRO_HOME } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import * as v from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/graph/applicationGraphValidators'
import { IntroductionContextType } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/types/introductionContext.type'
import { Edge, Graph, Route } from '@pretto/app/src/lib/graph/Graph'

import allPass from 'ramda/src/allPass'

const introductionRoute = new Route<IntroductionContextType>(`introduction`, `${INTRO_HOME}`)

export const applicationGraph = new Graph<IntroductionContextType>(`applicationGraph`, introductionRoute)

const brokerRoute = new Route<IntroductionContextType>(`broker`, `${INTRO_HOME}/sollicited-broker`)
const brokersProposalRoute = new Route<IntroductionContextType>(
  `brokersProposal`,
  `${INTRO_HOME}/sollicited-broker-rates`
)
const banksRoute = new Route<IntroductionContextType>(`banks`, `${INTRO_HOME}/banks`)
const banksToAvoidRoute = new Route<IntroductionContextType>(`banksToAvoid`, `${INTRO_HOME}/avoid-banks`)
const comortgagorRoute = new Route<IntroductionContextType>(`comortgagor`, `${INTRO_HOME}/project`)
const notaryRoute = new Route<IntroductionContextType>(`notary`, `${INTRO_HOME}/notary`)
const notaryContactRoute = new Route<IntroductionContextType>(`notaryContact`, `${INTRO_HOME}/notary/contact`)
const overdraftRoute = new Route<IntroductionContextType>(`overdraft`, `${INTRO_HOME}/banks/overdraft`)
const jointAccountRoute = new Route<IntroductionContextType>(`jointAccount`, `${INTRO_HOME}/banks/joint-account`)
const bankAccountsRoute = new Route<IntroductionContextType>(`bankAccounts`, `${INTRO_HOME}/banks/bank-accounts`)

applicationGraph.addRoute(brokerRoute)
applicationGraph.addRoute(brokersProposalRoute)
applicationGraph.addRoute(banksRoute)
applicationGraph.addRoute(banksToAvoidRoute)
applicationGraph.addRoute(comortgagorRoute)
applicationGraph.addRoute(notaryRoute)
applicationGraph.addRoute(notaryContactRoute)
applicationGraph.addRoute(overdraftRoute)
applicationGraph.addRoute(jointAccountRoute)
applicationGraph.addRoute(bankAccountsRoute)

applicationGraph.addEdge(new Edge<IntroductionContextType>(introductionRoute, brokerRoute, v.isHot))

applicationGraph.addEdge(new Edge<IntroductionContextType>(brokerRoute, brokersProposalRoute, v.hasBrokerProposal))

applicationGraph.addEdge(new Edge<IntroductionContextType>(brokerRoute, banksRoute, v.hasNoBrokerProposal))

applicationGraph.addEdge(new Edge<IntroductionContextType>(brokersProposalRoute, banksRoute, v.hasProposalFilled))

applicationGraph.addEdge(new Edge<IntroductionContextType>(introductionRoute, banksRoute, v.isNotHot))

applicationGraph.addEdge(new Edge<IntroductionContextType>(banksRoute, banksToAvoidRoute, v.hasBanksToAvoid))

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(banksRoute, comortgagorRoute, allPass([v.hasNoBanksToAvoid, v.hasComortgagor]))
)

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(
    banksRoute,
    notaryRoute,
    allPass([v.hasNoBanksToAvoid, v.hasNoComortgagor, v.hasSaleAgreementSigned])
  )
)

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(
    banksRoute,
    overdraftRoute,
    allPass([v.hasNoBanksToAvoid, v.hasNoComortgagor, v.hasNoSaleAgreementSigned, v.hasNoNotaryContact])
  )
)

applicationGraph.addEdge(new Edge<IntroductionContextType>(banksToAvoidRoute, comortgagorRoute, v.hasComortgagor))

applicationGraph.addEdge(new Edge<IntroductionContextType>(comortgagorRoute, notaryRoute, v.hasSaleAgreementSigned))

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(
    comortgagorRoute,
    overdraftRoute,
    allPass([v.hasComortgagor, v.hasNoSaleAgreementSigned])
  )
)

applicationGraph.addEdge(new Edge<IntroductionContextType>(notaryRoute, notaryContactRoute, v.hasNotaryContact))

applicationGraph.addEdge(new Edge<IntroductionContextType>(notaryRoute, overdraftRoute, v.hasNoNotaryContact))

applicationGraph.addEdge(new Edge<IntroductionContextType>(notaryContactRoute, overdraftRoute))

applicationGraph.addEdge(new Edge<IntroductionContextType>(overdraftRoute, jointAccountRoute, v.hasComortgagor))

applicationGraph.addEdge(new Edge<IntroductionContextType>(jointAccountRoute, bankAccountsRoute))

applicationGraph.addEdge(new Edge<IntroductionContextType>(overdraftRoute, bankAccountsRoute, v.hasNoComortgagor))

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(
    banksToAvoidRoute,
    notaryRoute,
    allPass([v.hasNoComortgagor, v.hasSaleAgreementSigned])
  )
)

applicationGraph.addEdge(
  new Edge<IntroductionContextType>(
    banksToAvoidRoute,
    overdraftRoute,
    allPass([v.hasNoComortgagor, v.hasNoSaleAgreementSigned])
  )
)
