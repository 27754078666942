import * as S from './StepsTemplate.styles'

export type StepsTemplateProps = {
  children: React.ReactNode
  isLoading?: boolean
  onClose: () => void
  onNext?: () => void
  previousStep: () => void
  progress: number
}

export const StepsTemplate = ({
  children,
  isLoading,
  onClose,
  onNext,
  previousStep,
  progress,
  ...props
}: StepsTemplateProps) => (
  <S.Template {...props}>
    <S.StyledProgressHeader onClose={onClose} previousStep={previousStep} progress={progress} />

    <S.MainContent>{children}</S.MainContent>

    {onNext && (
      <S.ButtonContainer>
        <S.NextButton onClick={onNext} loading={isLoading}>
          Suivant
        </S.NextButton>
      </S.ButtonContainer>
    )}
  </S.Template>
)
