import { g } from '@pretto/bricks/components/layout'

import { ReactNode } from 'react'
import styled from 'styled-components'

type InputListProps = {
  children: ReactNode
}

export const InputList = ({ children, ...props }: InputListProps) => (
  <StyledInputList {...props}>{children}</StyledInputList>
)

const StyledInputList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(2)};
`
